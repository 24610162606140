import { Component, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { AuthService } from './auth.service';
import { RecaptchaErrorParameters } from 'ng-recaptcha';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginModalComponent } from './login-modal/login-modal.component';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent {
  isLoading: boolean = false;
  error: string = null;
  isResolved: boolean = false;
  isCookie: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {}

  redirectByRole(): void {
    const role: string = localStorage.getItem('role');
    switch (role) {
      case 'ROLE_SUPER_ADMIN':
        this.router.navigate(['/panel/admin']);
        break;
    }
  }

  onSubmit(form: NgForm) {
    this.isCookie = sessionStorage.getItem('cookies') === 'true';

    if (!form.valid || !this.isCookie) {
      return;
    }
    const email = form.value.email;
    const password = form.value.password;

    this.isLoading = true;

    this.authService.login(email, password).subscribe(
      (resData) => {
        this.isLoading = false;
        this.router.navigate(['/locations']);
      },
      (errorRes) => {
        const modalRef = this.modalService.open(LoginModalComponent, {
          centered: true,
        });
        this.isLoading = false;
        this.error = errorRes;
      },
    );

    form.reset();
  }

  onJoinUs() {
    this.router.navigate(['register']);
  }

  onForgotPassword() {
    this.router.navigate(['reset-password']);
  }

  public resolved(captchaResponse: string): void {
    this.isResolved = true;
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  }
}
