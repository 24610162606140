<div class="container p-5">
  <div class="row p-3">
    <div class="col-12 step-container">
      <p class="font-weight-bold step" id="step1" *ngIf="stage==1">{{ 'locations.add-location.step1' | translate }}</p>
      <p class="font-weight-bold step" id="step2" *ngIf="stage==2">{{ 'locations.add-location.step2' | translate }}</p>
      <p class="font-weight-bold step" id="step3" *ngIf="stage==3">{{ 'locations.add-location.step3' | translate }}</p>
      <p class="font-weight-bold step" id="step4" *ngIf="stage==4">{{ 'locations.add-location.step4' | translate }}</p>
      <p class="font-weight-bold step" id="step5" *ngIf="stage==5">{{ 'locations.add-location.step5' | translate }}</p>
      <p class="font-weight-bold step" id="step6" *ngIf="stage==6">{{ 'locations.add-location.step6' | translate }}</p>
      <p class="font-weight-bold step" id="step7" *ngIf="stage==7">{{ 'locations.add-location.step7' | translate }}</p>
    </div>
  </div>

  <div class="row">
    <div class="col d-flex justify-content-center">
      <form class="d-flex flex-column mb-5 userForm" [formGroup]="locationForm" *ngIf="locationForm">

        <!--        Stage 1 -->
        <div id="stage1" *ngIf="stage==1">
          <div class="form-container">
            <div class="form-group">
              <label for="lname" class="font-weight-light">
                {{ 'locations.fieldNames.name' | translate }}
              </label>
              <input
                type="text"
                id="lname"
                class="form-control"
                formControlName="lname"
                [ngClass]="{ 'is-invalid': form.lname.invalid && (form.lname.touched) }"
              />
            </div>
            <div class="form-group">
              <label for="lcity" class="font-weight-light">
                {{ 'locations.fieldNames.city' | translate }}</label>
              <input
                type="text"
                id="lcity"
                class="form-control"
                formControlName="lcity"
                [ngClass]="{ 'is-invalid': form.lcity.invalid && (form.lcity.touched) }"
              />
            </div>
            <div class="form-group">
              <label for="ltype" class="font-weight-light">
                {{ 'locations.fieldNames.ltype' | translate }}
              </label>
              <select
                id="ltype"
                class="form-control"
                formControlName="ltype"
              >
                <option [value]="key" *ngFor="let key of typesSelection.keys()">
                  {{ typesSelection.get(key) }}
                </option>

              </select>
            </div>
            <div class="form-group">
              <label for="status" class="font-weight-light">
                {{ 'locations.fieldNames.status' | translate }}
              </label>
              <select
                id="status"
                class="form-control"
                formControlName="status"
              >
                <option [value]="key" *ngFor="let key of statusesSelection.keys()">
                  {{ statusesSelection.get(key) }}
                </option>

              </select>
            </div>
          </div>
        </div>

        <!--        Stage 2 -->
        <div id="stage2" *ngIf="stage==2">
          <div class="form-container">
            <div class="form-group">
              <label for="state" class="font-weight-light">
                {{ 'locations.fieldNames.state' | translate }}
              </label>
              <input
                type="text"
                id="state"
                class="form-control"
                formControlName="state"
                [ngClass]="{ 'is-invalid': form.state.invalid && (form.state.touched) }"
              />
            </div>
            <div class="form-group">
              <label for="city" class="font-weight-light">
                {{ 'locations.fieldNames.city' | translate }}
              </label>
              <input
                type="text"
                id="city"
                class="form-control"
                formControlName="city"
                [ngClass]="{ 'is-invalid': form.city.invalid && (form.city.touched) }"
              />
            </div>
            <div class="form-group">
              <label for="street" class="font-weight-light">
                {{ 'locations.fieldNames.street' | translate }}
              </label>
              <input
                type="text"
                id="street"
                class="form-control"
                formControlName="street"
                [ngClass]="{ 'is-invalid': form.street.invalid && (form.street.touched) }"
              />
            </div>
            <div class="form-group">
              <label for="number" class="font-weight-light">
                {{ 'locations.fieldNames.number' | translate }}
              </label>
              <input
                type="text"
                id="number"
                class="form-control"
                formControlName="number"
                [ngClass]="{ 'is-invalid': form.number.invalid && (form.number.touched) }"
              />
            </div>
            <div class="btn-container">
              <button class="btn btn-info ml-2" (click)="onAddAddress()" *ngIf="stage!=1">
                {{ 'locations.add-location.addAddress' | translate }}
              </button>
            </div>
          </div>

          <div class="row table-container" id="addressTable">
            <div class="col p-0">
              <table class="table">
                <thead class="thead-light">
                <tr>
                  <th scope="col">No.</th>
                  <th scope="col">
                    {{ 'locations.fieldNames.state' | translate }}</th>
                  <th scope="col">
                    {{ 'locations.fieldNames.city' | translate }}
                  </th>
                  <th scope="col">
                    {{ 'locations.fieldNames.street' | translate }}
                  </th>
                  <th scope="col">
                    {{ 'locations.fieldNames.number' | translate }}
                  </th>
                  <th scope="col">
                    {{ 'locations.delete' | translate }}
                  </th>
                </tr>
                </thead>
                <tbody *ngIf="location.addresses.length != 0">
                <tr *ngFor="let address of location.addresses; index as i">
                  <th scope="row" class="font-weight-bold">{{ i + 1 }}</th>
                  <td><b>{{ address.state }}</b></td>
                  <td>{{ address.city }}</td>
                  <td>{{ address.street }}</td>
                  <td>{{ address.number }}</td>
                  <td id="deleteAdressButton">
                    <button
                      name="delete"
                      type="button"
                      class="btn btn-outline-danger"
                      (click)="onDeleteAddress(address)"
                    >
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        class="bi bi-trash-fill"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"
                        />
                      </svg>
                    </button>
                  </td>
                </tr>
                </tbody>
                <tbody *ngIf="location.addresses.length == 0">
                <td colspan="6">
                  {{ 'locations.add-location.noAddresses' | translate }}
                </td>
                </tbody>
              </table>
            </div>
          </div>

        </div>

        <!--        Stage 3 -->
        <div id="stage3" *ngIf="stage==3">
          <div class="form-container">
            <div class="form-group">
              <label for="statusMpzp" class="font-weight-light">
                {{ 'locations.fieldNames.statusMpzp' | translate }}
              </label>
              <select
                id="statusMpzp"
                class="form-control"
                formControlName="statusMpzp"
              >
                <option [value]="key" *ngFor="let key of mpzpSelection.keys()">
                  {{ mpzpSelection.get(key) }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label for="linkMpzp" class="font-weight-light">
                {{ 'locations.fieldNames.linkMpzp' | translate }}
              </label>
              <input
                type="text"
                id="linkMpzp"
                class="form-control"
                formControlName="linkMpzp"
                [ngClass]="{ 'is-invalid': form.linkMpzp.invalid && (form.linkMpzp.touched) }"
              />
            </div>

            <div class="form-group">
              <label for="mpzp" class="font-weight-light">
                {{ 'locations.fieldNames.mpzp' | translate }}
              </label>
              <input
                type="text"
                id="mpzp"
                class="form-control"
                formControlName="mpzp"
                [ngClass]="{ 'is-invalid': form.mpzp.invalid && (form.mpzp.touched) }"
              />
            </div>

            <div class="form-group">
              <label for="wz" class="font-weight-light">
                {{ 'locations.fieldNames.wz' | translate }}
              </label>
              <select
                id="wz"
                class="form-control"
                formControlName="wz"
              >
                <option [value]="key" *ngFor="let key of wzSelection.keys()">
                  {{ wzSelection.get(key) }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label for="pnb" class="font-weight-light">
                {{ 'locations.fieldNames.pnb' | translate }}</label>
              <select
                id="pnb"
                class="form-control"
                formControlName="pnb"
              >
                <option [value]="key" *ngFor="let key of pnbSelection.keys()">
                  {{ pnbSelection.get(key) }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label for="linkGoogle" class="font-weight-light">
                {{ 'locations.fieldNames.linkGoogle' | translate }}</label>
              <input
                type="text"
                id="linkGoogle"
                class="form-control"
                formControlName="linkGoogle"
                [ngClass]="{ 'is-invalid': form.linkGoogle.invalid && (form.linkGoogle.touched) }"
              />
            </div>

            <div class="form-group">
              <label for="linkGeoportal" class="font-weight-light">
                {{ 'locations.fieldNames.linkGeoportal' | translate }}
              </label>
              <input
                type="text"
                id="linkGeoportal"
                class="form-control"
                formControlName="linkGeoportal"
                [ngClass]="{ 'is-invalid': form.linkGeoportal.invalid && (form.linkGeoportal.touched) }"
              />
            </div>

            <div class="form-group">
              <label for="surface" class="font-weight-light">
                {{ 'locations.fieldNames.surface' | translate }}
              </label>
              <input class="form-control"
                     type="number"
                     id="surface"
                     formControlName="surface">
            </div>

            <div class="form-group">
              <label for="isBuyAll" class="font-weight-light">
                {{ 'locations.fieldNames.isBuyAll' | translate }}
              </label>
              <div id="isBuyAll">
                <label for="isBuyAllTrue" class="font-check-label">
                  {{ 'locations.add-location.yes' | translate }}
                </label>
                <input
                  type="radio"
                  id="isBuyAllTrue"
                  [value]="true"
                  class="form-check-label"
                  formControlName="isBuyAll"
                  [(ngModel)]="this.location.isBuyAll"
                />
                <label for="isBuyAllFalse" class="font-check-label">
                  {{ 'locations.add-location.no' | translate }}
                </label>
                <input
                  type="radio"
                  id="isBuyAllFalse"
                  [value]="false"
                  class="form-check-label"
                  formControlName="isBuyAll"
                  [(ngModel)]="this.location.isBuyAll"
                />
              </div>
            </div>

            <div class="form-group">
              <label for="buildingYear" class="font-weight-light">
                {{ 'locations.fieldNames.buildingYear' | translate }}
              </label>
              <input class="form-control"
                     type="number"
                     id="buildingYear"
                     formControlName="buildingYear">
            </div>

            <div class="form-group">
              <label for="buildingQuantity" class="font-weight-light">
                {{ 'locations.fieldNames.buildingQuantity' | translate }}
              </label>
              <input class="form-control"
                     type="number"
                     id="buildingQuantity"
                     formControlName="buildingQuantity">
            </div>

            <div class="form-group">
              <label for="buildingContignationUnderground" class="font-weight-light">
                {{ 'locations.fieldNames.buildingContignationUnderground' | translate }}
              </label>
              <input class="form-control"
                     type="number"
                     id="buildingContignationUnderground"
                     formControlName="buildingContignationUnderground">
            </div>

            <div class="form-group">
              <label for="buildingContignationGround" class="font-weight-light">
                {{ 'locations.fieldNames.buildingContignationGround' | translate }}
              </label>
              <input class="form-control"
                     type="number"
                     id="buildingContignationGround"
                     formControlName="buildingContignationGround">
            </div>

            <div class="form-group">
              <label for="buildingStage" class="font-weight-light">
                {{ 'locations.fieldNames.buildingStage' | translate }}
              </label>
              <select
                id="buildingStage"
                class="form-control"
                formControlName="buildingStage"
              >
                <option [value]="key" *ngFor="let key of buildingStageSelection.keys()">
                  {{ buildingStageSelection.get(key) }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label for="statusExpandable" class="font-weight-light">
                {{ 'locations.fieldNames.statusExpandable' | translate }}
              </label>
              <select
                id="statusExpandable"
                class="form-control"
                formControlName="statusExpandable"
              >
                <option [value]="key" *ngFor="let key of expandapleSelection.keys()">
                  {{ expandapleSelection.get(key) }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label for="pum" class="font-weight-light">
                {{ 'locations.fieldNames.pum' | translate }}
              </label>
              <input class="form-control"
                     type="number"
                     id="pum"
                     formControlName="pum">
            </div>

            <div class="form-group">
              <label for="puu" class="font-weight-light">
                {{ 'locations.fieldNames.puu' | translate }}
              </label>
              <input class="form-control"
                     type="number"
                     id="puu"
                     formControlName="puu">
            </div>

            <div class="form-group">
              <label for="puuOffice" class="font-weight-light">
                {{ 'locations.fieldNames.puuOffice' | translate }}
              </label>
              <input class="form-control"
                     type="number"
                     id="puuOffice"
                     formControlName="puuOffice">
            </div>

            <div class="form-group">
              <label for="puuTrade" class="font-weight-light">
                {{ 'locations.fieldNames.puuTrade' | translate }}
              </label>
              <input class="form-control"
                     type="number"
                     id="puuTrade"
                     formControlName="puuTrade">
            </div>

            <div class="form-group">
              <label for="surfaceCommon" class="font-weight-light">
                {{ 'locations.fieldNames.surfaceCommon' | translate }}
              </label>
              <input class="form-control"
                     type="number"
                     id="surfaceCommon"
                     formControlName="surfaceCommon">
            </div>

            <div class="form-group">
              <label for="price" class="font-weight-light">
                {{ 'locations.fieldNames.price' | translate }}
              </label>
              <input class="form-control"
                     type="number"
                     id="price"
                     formControlName="price">
            </div>

            <div class="form-group">
              <label for="currency" class="font-weight-light">
                {{ 'locations.fieldNames.currency' | translate }}
              </label>
              <input
                type="text"
                id="currency"
                class="form-control"
                formControlName="currency"
                [ngClass]="{ 'is-invalid': form.currency.invalid && (form.currency.touched) }"
              />
            </div>

            <div class="form-group">
              <label for="pricePuu" class="font-weight-light">
                {{ 'locations.fieldNames.pricePuu' | translate }}
              </label>
              <input class="form-control"
                     type="number"
                     id="pricePuu"
                     formControlName="pricePuu">
            </div>

            <div class="form-group">
              <label for="pricePum" class="font-weight-light">
                {{ 'locations.fieldNames.pricePum' | translate }}
              </label>
              <input class="form-control"
                     type="number"
                     id="pricePum"
                     formControlName="pricePum">
            </div>

            <div class="form-group">
              <label for="statusWater" class="font-weight-light">
                {{ 'locations.fieldNames.statusWater' | translate }}</label>
              <select
                id="statusWater"
                class="form-control"
                formControlName="statusWater"
              >
                <option [value]="key" *ngFor="let key of mediaSelection.keys()">
                  {{ mediaSelection.get(key) }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label for="statusElectricity" class="font-weight-light">
                {{ 'locations.fieldNames.statusElectricity' | translate }}</label>
              <select
                id="statusElectricity"
                class="form-control"
                formControlName="statusElectricity"
              >
                <option [value]="key" *ngFor="let key of mediaSelection.keys()">
                  {{ mediaSelection.get(key) }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label for="statusGas" class="font-weight-light">
                {{ 'locations.fieldNames.statusGas' | translate }}</label>
              <select
                id="statusGas"
                class="form-control"
                formControlName="statusGas"
              >
                <option [value]="key" *ngFor="let key of mediaSelection.keys()">
                  {{ mediaSelection.get(key) }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label for="statusCanalization" class="font-weight-light">
                {{ 'locations.fieldNames.statusCanalization' | translate }}
              </label>
              <select
                id="statusCanalization"
                class="form-control"
                formControlName="statusCanalization"
              >
                <option [value]="key" *ngFor="let key of mediaSelection.keys()">
                  {{ mediaSelection.get(key) }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label for="currencyNoi" class="font-weight-light">
                {{ 'locations.fieldNames.currencyNoi' | translate }}
              </label>
              <input
                type="text"
                id="currencyNoi"
                class="form-control"
                formControlName="currencyNoi"
                [ngClass]="{ 'is-invalid': form.currencyNoi.invalid && (form.currencyNoi.touched) }"
              />
            </div>

            <div class="form-group">
              <label for="currencyGoi" class="font-weight-light">
                {{ 'locations.fieldNames.currencyGoi' | translate }}
              </label>
              <input
                type="text"
                id="currencyGoi"
                class="form-control"
                formControlName="currencyGoi"
                [ngClass]="{ 'is-invalid': form.currencyGoi.invalid && (form.currencyGoi.touched) }"
              />
            </div>

            <div class="form-group">
              <label for="monthsWault" class="font-weight-light">
                {{ 'locations.fieldNames.monthsWault' | translate }}
              </label>
              <input
                type="text"
                id="monthsWault"
                class="form-control"
                formControlName="monthsWault"
                [ngClass]="{ 'is-invalid': form.monthsWault.invalid && (form.monthsWault.touched) }"
              />
            </div>

            <div class="form-group">
              <label for="lyield" class="font-weight-light">
                {{ 'locations.fieldNames.lyield' | translate }}
              </label>
              <input class="form-control"
                     type="number"
                     id="lyield"
                     formControlName="lyield">
            </div>

            <div class="form-group">
              <label for="certificates" class="font-weight-light">
                {{ 'locations.fieldNames.certificates' | translate }}
              </label>
              <input
                type="text"
                id="certificates"
                class="form-control"
                formControlName="certificates"
                [ngClass]="{ 'is-invalid': form.certificates.invalid && (form.certificates.touched) }"
              />
            </div>

            <div class="form-group">
              <label for="height" class="font-weight-light">
                {{ 'locations.fieldNames.height' | translate }}
              </label>
              <input class="form-control"
                     type="number"
                     id="height"
                     formControlName="height">
            </div>

            <div class="form-group">
              <label for="streetMain" class="font-weight-light">
                {{ 'locations.fieldNames.streetMain' | translate }}
              </label>
              <input
                type="text"
                id="streetMain"
                class="form-control"
                formControlName="streetMain"
                [ngClass]="{ 'is-invalid': form.streetMain.invalid && (form.streetMain.touched) }"
              />
            </div>

            <div class="form-group">
              <label for="minBiol" class="font-weight-light">
                {{ 'locations.fieldNames.minBiol' | translate }}
              </label>
              <input
                type="text"
                id="minBiol"
                class="form-control"
                formControlName="minBiol"
                [ngClass]="{ 'is-invalid': form.minBiol.invalid && (form.minBiol.touched) }"
              />
            </div>

            <div class="form-group">
              <label for="isExitroad" class="font-weight-light">
                {{ 'locations.fieldNames.isExitroad' | translate }}
              </label>
              <div id="isExitroad">
                <label for="isExitroadTrue" class="font-check-label">
                  {{ 'locations.add-location.yes' | translate }}
                </label>
                <input
                  type="radio"
                  id="isExitroadTrue"
                  [value]="true"
                  class="form-check-label"
                  formControlName="isExitroad"
                  [(ngModel)]="this.location.isExitroad"
                />
              </div>
              <label for="isExitroadFalse" class="font-check-label">
                {{ 'locations.add-location.no' | translate }}
              </label>
              <input
                type="radio"
                id="isExitroadFalse"
                [value]="false"
                class="form-check-label"
                formControlName="isExitroad"
                [(ngModel)]="this.location.isExitroad"
              />
            </div>

            <div class="form-group">
              <label for="isRelicEvidence" class="font-weight-light">
                {{ 'locations.fieldNames.isRelicEvidence' | translate }}
              </label>
              <div id="isRelicEvidence">
                <label for="isRelicEvidenceTrue" class="font-check-label">
                  {{ 'locations.add-location.yes' | translate }}
                </label>
                <input
                  type="radio"
                  id="isRelicEvidenceTrue"
                  [value]="true"
                  class="form-check-label"
                  formControlName="isRelicEvidence"
                  [(ngModel)]="this.location.isRelicEvidence"
                />
              </div>
              <label for="isRelicEvidenceFalse" class="font-check-label">
                {{ 'locations.add-location.no' | translate }}
              </label>
              <input
                type="radio"
                id="isRelicEvidenceFalse"
                [value]="false"
                class="form-check-label"
                formControlName="isRelicEvidence"
                [(ngModel)]="this.location.isRelicEvidence"
              />
            </div>

            <div class="form-group">
              <label for="isRelicRegister" class="font-weight-light">
                {{ 'locations.fieldNames.isRelicRegister' | translate }}
              </label>
              <div id="isRelicRegister">
                <label for="isRelicRegisterTrue" class="font-check-label">
                  {{ 'locations.add-location.yes' | translate }}
                </label>
                <input
                  type="radio"
                  id="isRelicRegisterTrue"
                  [value]="true"
                  class="form-check-label"
                  formControlName="isRelicRegister"
                  [(ngModel)]="this.location.isRelicRegister"
                />
              </div>
              <label for="isRelicRegisterFalse" class="font-check-label">
                {{ 'locations.add-location.no' | translate }}
              </label>
              <input
                type="radio"
                id="isRelicRegisterFalse"
                [value]="false"
                class="form-check-label"
                formControlName="isRelicRegister"
                [(ngModel)]="this.location.isRelicRegister"
              />
            </div>

            <div class="form-group">
              <label for="isTenants" class="font-weight-light">
                {{ 'locations.fieldNames.isTenants' | translate }}
              </label>
              <div id="isTenants">
                <label for="isTenantsTrue" class="font-check-label">
                  {{ 'locations.add-location.yes' | translate }}
                </label>
                <input
                  type="radio"
                  id="isTenantsTrue"
                  [value]="true"
                  class="form-check-label"
                  formControlName="isTenants"
                  [(ngModel)]="this.location.isTenants"
                />
              </div>
              <label for="isTenantsFalse" class="font-check-label">
                {{ 'locations.add-location.no' | translate }}
              </label>
              <input
                type="radio"
                id="isTenantsFalse"
                [value]="false"
                class="form-check-label"
                formControlName="isTenants"
                [(ngModel)]="this.location.isTenants"
              />
            </div>

            <div class="form-group">
              <label for="transactionTypes" class="font-weight-light">
                {{ 'locations.fieldNames.transactionTypes' | translate }}
              </label>
              <input
                type="text"
                id="transactionTypes"
                class="form-control"
                formControlName="transactionTypes"
                [ngClass]="{ 'is-invalid': form.transactionTypes.invalid && (form.transactionTypes.touched) }"
              />
            </div>

            <div class="form-group">
              <label for="transactionTerms">
                {{ 'locations.fieldNames.transactionTerms' | translate }}
              </label>
              <textarea
                class="form-control"
                id="transactionTerms"
                rows="3"
                formControlName="transactionTerms"> </textarea>
            </div>

            <div class="form-group">
              <label for="nature" class="font-weight-light">
                {{ 'locations.fieldNames.nature' | translate }}
              </label>
              <input
                type="text"
                id="nature"
                class="form-control"
                formControlName="nature"
                [ngClass]="{ 'is-invalid': form.nature.invalid && (form.nature.touched) }"
              />
            </div>

            <div class="form-group">
              <label for="isAccessible" class="font-weight-light">
                {{ 'locations.fieldNames.isAccessible' | translate }}
              </label>
              <div id="isAccessible">
                <label for="isAccessibleTrue" class="font-check-label">
                  {{ 'locations.add-location.yes' | translate }}
                </label>
                <input
                  type="radio"
                  id="isAccessibleTrue"
                  [value]="true"
                  class="form-check-label"
                  formControlName="isAccessible"
                  [(ngModel)]="this.location.isAccessible"
                />
              </div>
              <label for="isAccessibleFalse" class="font-check-label">
                {{ 'locations.add-location.no' | translate }}
              </label>
              <input
                type="radio"
                id="isAccessibleFalse"
                [value]="false"
                class="form-check-label"
                formControlName="isAccessible"
                [(ngModel)]="this.location.isAccessible"
              />
            </div>

            <div class="form-group">
              <label for="other">
                {{ 'locations.fieldNames.other' | translate }}
              </label>
              <textarea
                class="form-control"
                id="other"
                rows="3"
                formControlName="other"> </textarea>
            </div>

            <div class="form-group">
              <label for="tax" class="font-weight-light">
                {{ 'locations.fieldNames.tax' | translate }}</label>
              <select
                id="tax"
                class="form-control"
                formControlName="tax"
              >
                <option [value]="key" *ngFor="let key of taxSelection.keys()">
                  {{ taxSelection.get(key) }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label for="servitude" class="font-weight-light">
                {{ 'locations.fieldNames.servitude' | translate }}
              </label>
              <input class="form-control"
                     type="number"
                     id="servitude"
                     formControlName="servitude">
            </div>
          </div>
        </div>

        <!--        Stage 4 -->
        <div id="stage4" *ngIf="stage==4">
          <div class="form-container">
            <div class="form-group">
              <label for="isGarage" class="font-weight-light">
                {{ 'locations.fieldNames.isGarage' | translate }}
              </label>
              <div id="isGarage">
                <label for="isGarageTrue"
                       class="font-check-label">{{ 'locations.add-location.yes' | translate }}</label>
                <input
                  type="radio"
                  id="isGarageTrue"
                  [value]="true"
                  class="form-check-label"
                  formControlName="isGarage"
                  [(ngModel)]="this.location.isGarage"
                />
                <label for="isGarageFalse"
                       class="font-check-label">{{ 'locations.add-location.no' | translate }}</label>
                <input
                  type="radio"
                  id="isGarageFalse"
                  [value]="false"
                  class="form-check-label"
                  formControlName="isGarage"
                  [(ngModel)]="this.location.isGarage"
                />
              </div>
            </div>

            <div class="form-group">
              <label for="isGarageGround"
                     class="font-weight-light"> {{ 'locations.fieldNames.isGarageGround' | translate }}</label>
              <div id="isGarageGround">
                <label for="isGarageGroundTrue"
                       class="font-check-label">{{ 'locations.add-location.yes' | translate }}</label>
                <input
                  type="radio"
                  id="isGarageGroundTrue"
                  [value]="true"
                  class="form-check-label"
                  formControlName="isGarageGround"
                  [(ngModel)]="this.location.isGarageGround"
                />
                <label for="isGarageGroundFalse"
                       class="font-check-label">{{ 'locations.add-location.no' | translate }}</label>
                <input
                  type="radio"
                  id="isGarageGroundFalse"
                  [value]="false"
                  class="form-check-label"
                  formControlName="isGarageGround"
                  [(ngModel)]="this.location.isGarageGround"
                />
              </div>
            </div>

            <div class="form-group">
              <label for="garageGroundSurface"
                     class="font-weight-light">* {{ 'locations.fieldNames.garageGroundSurface' | translate }}</label>
              <input class="form-control"
                     type="number"
                     id="garageGroundSurface"
                     formControlName="garageGroundSurface">
            </div>

            <div class="form-group">
              <label for="garageGroundFloors"
                     class="font-weight-light"> {{ 'locations.fieldNames.garageGroundFloors' | translate }}</label>
              <input class="form-control"
                     type="number"
                     id="garageGroundFloors"
                     formControlName="garageGroundFloors">
            </div>

            <div class="form-group">
              <label for="garageGroundQuantity" class="font-weight-light">
                {{ 'locations.fieldNames.garageGroundQuantity' | translate }}</label>
              <input class="form-control"
                     type="number"
                     id="garageGroundQuantity"
                     formControlName="garageGroundQuantity">
            </div>

            <div class="form-group">
              <label for="isGarageUnderground" class="font-weight-light">
                {{ 'locations.fieldNames.isGarageUnderground' | translate }}

              </label>
              <div id="isGarageUnderground">
                <label for="isGarageUndergroundTrue"
                       class="font-check-label">{{ 'locations.add-location.yes' | translate }}</label>
                <input
                  type="radio"
                  id="isGarageUndergroundTrue"
                  [value]="true"
                  class="form-check-label"
                  formControlName="isGarageUnderground"
                  [(ngModel)]="this.location.isGarageUnderground"
                />
              </div>
              <label for="isGarageUndergroundFalse"
                     class="font-check-label">{{ 'locations.add-location.no' | translate }}</label>
              <input
                type="radio"
                id="isGarageUndergroundFalse"
                [value]="false"
                class="form-check-label"
                formControlName="isGarageUnderground"
                [(ngModel)]="this.location.isGarageUnderground"
              />
            </div>

            <div class="form-group">
              <label for="isGarageUndergroundPlatforms" class="font-weight-light">
                {{ 'locations.fieldNames.isGarageUndergroundPlatforms' | translate }}</label>
              <div id="isGarageUndergroundPlatforms">
                <label for="isGarageUndergroundPlatformsTrue"
                       class="font-check-label">{{ 'locations.add-location.yes' | translate }}</label>
                <input
                  type="radio"
                  id="isGarageUndergroundPlatformsTrue"
                  [value]="true"
                  class="form-check-label"
                  formControlName="isGarageUndergroundPlatforms"
                  [(ngModel)]="this.location.isGarageUndergroundPlatforms"
                />
              </div>
              <label for="isGarageUndergroundPlatformsFalse"
                     class="font-check-label">{{ 'locations.add-location.no' | translate }}</label>
              <input
                type="radio"
                id="isGarageUndergroundPlatformsFalse"
                [value]="false"
                class="form-check-label"
                formControlName="isGarageUndergroundPlatforms"
                [(ngModel)]="this.location.isGarageUndergroundPlatforms"
              />
            </div>

            <div class="form-group">
              <label for="garageUndergroundFloors" class="font-weight-light">
                {{ 'locations.fieldNames.garageUndergroundFloors' | translate }}
              </label>
              <input class="form-control"
                     type="number"
                     id="garageUndergroundFloors"
                     formControlName="garageUndergroundFloors">
            </div>

            <div class="form-group">
              <label for="garageUndergroundQuantity" class="font-weight-light">
                {{ 'locations.fieldNames.garageUndergroundQuantity' | translate }}
              </label>
              <input class="form-control"
                     type="number"
                     id="garageUndergroundQuantity"
                     formControlName="garageUndergroundQuantity">
            </div>

            <div class="form-group">
              <label for="garageUndergroundSurface" class="font-weight-light">*
                {{ 'locations.fieldNames.garageUndergroundSurface' | translate }}</label>
              <input class="form-control"
                     type="number"
                     id="garageUndergroundSurface"
                     formControlName="garageUndergroundSurface">
            </div>

            <div class="form-group">
              <label for="garageQuantity" class="font-weight-light">
                {{ 'locations.fieldNames.garageQuantity' | translate }}
              </label>
              <input class="form-control"
                     type="number"
                     id="garageQuantity"
                     formControlName="garageQuantity">
            </div>

            <div class="form-group">
              <label for="garageSurface" class="font-weight-light">
                {{ 'locations.fieldNames.garageSurface' | translate }}
              </label>
              <input class="form-control"
                     type="number"
                     id="garageSurface"
                     formControlName="garageSurface">
            </div>

            <div class="form-group">
              <label for="garageBikes" class="font-weight-light">
                {{ 'locations.fieldNames.garageBikes' | translate }}
              </label>
              <input class="form-control"
                     type="number"
                     id="garageBikes"
                     formControlName="garageBikes">
            </div>
          </div>
        </div>

        <!--        Stage 5 -->
        <div id="stage5" *ngIf="stage==5">
          <div class="form-container">
            <div class="form-group">
              <label for="name" class="font-weight-light">
                {{ 'locations.fieldNames.name' | translate }}
              </label>
              <input
                type="text"
                id="name"
                class="form-control"
                formControlName="name"
                [ngClass]="{ 'is-invalid': form.name.invalid && (form.name.touched) }"
              />
            </div>

            <div class="form-group">
              <label for="surname" class="font-weight-light">
                {{ 'locations.fieldNames.surname' | translate }}
              </label>
              <input
                type="text"
                id="surname"
                class="form-control"
                formControlName="surname"
                [ngClass]="{ 'is-invalid': form.surname.invalid && (form.name.touched) }"
              />
            </div>

            <div class="form-group">
              <label for="address" class="font-weight-light">
                {{ 'locations.fieldNames.address' | translate }}
              </label>
              <input
                type="text"
                id="address"
                class="form-control"
                formControlName="address"
                [ngClass]="{ 'is-invalid': form.address.invalid && (form.name.touched) }"
              />
            </div>

            <div class="form-group">
              <label for="phone" class="font-weight-light">
                {{ 'locations.fieldNames.phone' | translate }}
              </label>
              <input
                type="text"
                id="phone"
                class="form-control"
                formControlName="phone"
                [ngClass]="{ 'is-invalid': form.phone.invalid && (form.name.touched) }"
              />
            </div>

            <div class="form-group">
              <label for="email" class="font-weight-light">
                {{ 'locations.fieldNames.email' | translate }}
              </label>
              <input
                type="text"
                id="email"
                class="form-control"
                formControlName="email"
                [ngClass]="{ 'is-invalid': form.email.invalid && (form.name.touched) }"
              />
            </div>

            <div class="form-group">
              <label for="isOwner" class="font-weight-light">
                {{ 'locations.fieldNames.isOwner' | translate }}
              </label>
              <div id="isOwner">
                <label for="isOwnerTrue" class="font-check-label">{{ 'locations.add-location.yes' | translate }}</label>
                <input
                  type="radio"
                  id="isOwnerTrue"
                  value="true"
                  class="form-check-label"
                  formControlName="isOwner"
                />
              </div>
              <label for="isOwnerFalse" class="font-check-label">{{ 'locations.add-location.no' | translate }}</label>
              <input
                type="radio"
                id="isOwnerFalse"
                value="false"
                class="form-check-label"
                formControlName="isOwner"
              />
            </div>

            <div class="form-group">
              <label for="isBroker" class="font-weight-light">
                {{ 'locations.fieldNames.isBroker' | translate }}
              </label>
              <div id="isBroker">
                <label for="isBrokerTrue"
                       class="font-check-label">{{ 'locations.add-location.yes' | translate }}</label>
                <input
                  type="radio"
                  id="isBrokerTrue"
                  value="true"
                  class="form-check-label"
                  formControlName="isBroker"
                />
              </div>
              <label for="isBrokerFalse" class="font-check-label">{{ 'locations.add-location.no' | translate }}</label>
              <input
                type="radio"
                id="isBrokerFalse"
                value="false"
                class="form-check-label"
                formControlName="isBroker"
              />
            </div>

            <div class="form-group">
              <label for="company" class="font-weight-light">
                {{ 'locations.fieldNames.company' | translate }}
              </label>
              <input
                type="text"
                id="company"
                class="form-control"
                formControlName="company"
                [ngClass]="{ 'is-invalid': form.company.invalid && (form.company.touched) }"
              />
            </div>

            <div class="form-group">
              <label for="isPreliminary" class="font-weight-light">
                {{ 'locations.fieldNames.isPreliminary' | translate }}
              </label>
              <div id="isPreliminary">
                <label for="isPreliminaryTrue"
                       class="font-check-label">{{ 'locations.add-location.yes' | translate }}</label>
                <input
                  type="radio"
                  id="isPreliminaryTrue"
                  value="true"
                  class="form-check-label"
                  formControlName="isPreliminary"
                />
              </div>
              <label for="isPreliminaryFalse"
                     class="font-check-label">{{ 'locations.add-location.no' | translate }}</label>
              <input
                type="radio"
                id="isPreliminaryFalse"
                value="false"
                class="form-check-label"
                formControlName="isPreliminary"
              />
            </div>

            <div class="form-group">
              <label for="datePreliminary" class="font-weight-light">
                {{ 'locations.fieldNames.datePreliminary' | translate }}
              </label>
              <div class="input-group">
                <input id="datePreliminary"
                       class="form-control"
                       placeholder="dd-mm-rrrr"
                       formControlName="datePreliminary"
                       ngbDatepicker #d="ngbDatepicker"
                >
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="isDirect" class="font-weight-light">
                {{ 'locations.fieldNames.isDirect' | translate }}
              </label>
              <div id="isDirect">
                <label for="isDirectTrue"
                       class="font-check-label">{{ 'locations.add-location.yes' | translate }}</label>
                <input
                  type="radio"
                  id="isDirectTrue"
                  value="true"
                  class="form-check-label"
                  formControlName="isDirect"
                />
              </div>
              <label for="isDirectFalse" class="font-check-label">{{ 'locations.add-location.no' | translate }}</label>
              <input
                type="radio"
                id="isDirectFalse"
                value="false"
                class="form-check-label"
                formControlName="isDirect"
              />
            </div>

            <div class="form-group">
              <label for="isAgreement" class="font-weight-light">
                {{ 'locations.fieldNames.isAgreement' | translate }}
              </label>
              <div id="isAgreement">
                <label for="isAgreementTrue"
                       class="font-check-label">{{ 'locations.add-location.yes' | translate }}</label>
                <input
                  type="radio"
                  id="isAgreementTrue"
                  value="true"
                  class="form-check-label"
                  formControlName="isAgreement"
                />
              </div>
              <label for="isAgreementFalse"
                     class="font-check-label">{{ 'locations.add-location.no' | translate }}</label>
              <input
                type="radio"
                id="isAgreementFalse"
                value="false"
                class="form-check-label"
                formControlName="isAgreement"
              />
            </div>

            <div class="form-group">
              <label for="agreementTermsAk">
                {{ 'locations.fieldNames.agreementTermsAk' | translate }}
              </label>
              <textarea
                class="form-control"
                id="agreementTermsAk"
                rows="3"
                formControlName="agreementTermsAk"> </textarea>
            </div>

            <div class="form-group">
              <label for="agreementTermsBroker">
                {{ 'locations.fieldNames.agreementTermsBroker' | translate }}
              </label>
              <textarea
                class="form-control"
                id="agreementTermsBroker"
                rows="3"
                formControlName="agreementTermsBroker"> </textarea>
            </div>

            <button class="btn btn-info ml-2" (click)="onAddOwner()">
              {{ 'locations.add-location.addOwner' | translate }}
            </button>
          </div>


          <div class="row table-container" id="ownersTable">
            <div class="col p-0">
              <table class="table">
                <thead class="thead-light">
                <tr>
                  <th scope="col">No.</th>
                  <th scope="col">{{ 'locations.fieldNames.name' | translate }}</th>
                  <th scope="col">{{ 'locations.fieldNames.surname' | translate }}</th>
                  <th scope="col">{{ 'locations.fieldNames.phone' | translate }}</th>
                  <th scope="col">{{ 'locations.fieldNames.email' | translate }}</th>
                  <th scope="col">{{ 'locations.fieldNames.isOwner' | translate }}</th>
                  <th scope="col">{{ 'locations.fieldNames.isBroker' | translate }}</th>
                </tr>
                </thead>
                <tbody *ngIf="location.owners.length != 0">
                <tr *ngFor="let owner of location.owners; index as i">
                  <th scope="row" class="font-weight-bold">{{ i + 1 }}</th>
                  <td><b>{{ owner.name }}</b></td>
                  <td>{{ owner.surname }}</td>
                  <td>{{ owner.phone }}</td>
                  <td>{{ owner.email }}</td>
                  <td>{{ owner.isOwner }}</td>
                  <td>{{ owner.isBroker }}</td>
                  <td id="deleteOwnerButton">
                    <button
                      name="delete"
                      type="button"
                      class="btn btn-outline-danger"
                      (click)="onDeleteOwner(owner)"
                    >
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        class="bi bi-trash-fill"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"
                        />
                      </svg>
                    </button>
                  </td>
                </tr>
                </tbody>
                <tbody *ngIf="location.owners.length == 0">
                <td colspan="6">
                  {{ 'locations.add-location.noOwners' | translate }}
                </td>
                </tbody>
              </table>
            </div>
          </div>

        </div>

        <!--        Stage 6 -->
        <div id="stage6" *ngIf="stage==6">

          <div class="form-group">
            <label for="pnumber" class="font-weight-light">
              {{ 'locations.fieldNames.pnumber' | translate }}
            </label>
            <input
              type="text"
              id="pnumber"
              class="form-control"
              formControlName="pnumber"
              [ngClass]="{ 'is-invalid': form.pnumber.invalid && (form.pnumber.touched) }"
            />
          </div>
          <div class="form-group">
            <label for="territory" class="font-weight-light">
              {{ 'locations.fieldNames.territory' | translate }}
            </label>
            <input
              type="text"
              id="territory"
              class="form-control"
              formControlName="territory"
              [ngClass]="{ 'is-invalid': form.territory.invalid && (form.territory.touched) }"
            />
          </div>
          <div class="form-group">
            <label for="precint" class="font-weight-light">
              {{ 'locations.fieldNames.precinct' | translate }}
            </label>
            <input
              type="text"
              id="precint"
              class="form-control"
              formControlName="precint"
              [ngClass]="{ 'is-invalid': form.precint.invalid && (form.precint.touched) }"
            />
          </div>

          <div class="form-group">
            <label for="kw" class="font-weight-light">
              {{ 'locations.fieldNames.kw' | translate }}
            </label>
            <input
              type="text"
              id="kw"
              class="form-control"
              formControlName="kw"
              [ngClass]="{ 'is-invalid': form.kw.invalid && (form.kw.touched) }"
            />
          </div>

          <button class="btn btn-info ml-2" (click)="onAddPlot()">
            {{ 'locations.add-location.addPlot' | translate }}
          </button>
          <div class="row table-container" id="plotsTable">
            <div class="col p-0">
              <table class="table">
                <thead class="thead-light">
                <tr>
                  <th scope="col">No.</th>
                  <th scope="col">{{ 'locations.fieldNames.pnumber' | translate }}</th>
                  <th scope="col">{{ 'locations.fieldNames.territory' | translate }}</th>
                  <th scope="col">{{ 'locations.fieldNames.precinct' | translate }}</th>
                  <th scope="col">{{ 'locations.fieldNames.kw' | translate }}</th>
                  <th scope="col">{{ 'locations.add-location.delete' | translate }}</th>
                </tr>
                </thead>
                <tbody *ngIf="location.plots.length != 0">
                <tr *ngFor="let plot of location.plots; index as i">
                  <th scope="row" class="font-weight-bold">{{ i + 1 }}</th>
                  <td><b>{{ plot.pnumber }}</b></td>
                  <td>{{ plot.territory }}</td>
                  <td>{{ plot.precinct }}</td>
                  <td>{{ plot.kw }}</td>
                  <td id="deletePlotButton">
                    <button
                      name="delete"
                      type="button"
                      class="btn btn-outline-danger"
                      (click)="onDeletePlot(plot)"
                    >
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        class="bi bi-trash-fill"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"
                        />
                      </svg>
                    </button>
                  </td>
                </tr>
                </tbody>
                <tbody *ngIf="location.plots.length == 0">
                <td colspan="6">{{ 'locations.add-location.noPlots' | translate }}</td>
                </tbody>
              </table>
            </div>
          </div>

        </div>

        <!--        Stage 7 -->
        <div id="stage7" *ngIf="stage==7">
          <div class="row" id="allDataTable">
            <div class="col p-0">
              <table class="table">
                <thead class="thead-light">
                <tr>
                  <th scope="col">{{ 'locations.details.field' | translate }}</th>
                  <th scope="col">{{ 'locations.details.value' | translate }}</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let key of locationMap.keys()">
                  <tr>
                    <td>{{ 'locations.fieldNames.' + key | translate }}</td>
                    <td>{{ getGeneralValue(key) }}</td>
                  </tr>
                </ng-container>
                </tbody>
              </table>
            </div>
          </div>

          <strong *ngIf="addressesMap.size != 0">{{ 'locations.details.addresses' | translate }}</strong>

          <div class="row" id="allAdressesTable" *ngIf="addressesMap.size != 0">
            <div class="col p-0">
              <table class="table">
                <thead class="thead-light">
                <tr>
                  <th scope="col">{{ 'locations.details.field' | translate }}</th>
                  <th scope="col">{{ 'locations.details.value' | translate }}</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let key of addressesMap.keys(); index as i">
                  <tr>
                    <td colspan="2">
                      <strong>{{ 'locations.details.addressNumber' | translate }} {{i + 1}}:</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let addressKey of addressesMap.get(key).keys()">
                    <tr>
                      <td>{{ 'locations.fieldNames.' + addressKey | translate}}</td>
                      <td>{{ getDetailValue(key, addressKey, 'address') }}</td>
                    </tr>
                  </ng-container>
                </ng-container>
                </tbody>
              </table>
            </div>
          </div>

          <strong *ngIf="ownersMap.size != 0">{{ 'locations.details.owners' | translate }}</strong>

          <div class="row" id="allOwnersTable" *ngIf="ownersMap.size != 0">
            <div class="col p-0">
              <table class="table">
                <thead class="thead-light">
                <tr>
                  <th scope="col">{{ 'locations.details.field' | translate }}</th>
                  <th scope="col">{{ 'locations.details.value' | translate }}</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let key of ownersMap.keys(); index as i">
                  <tr>
                    <td colspan="2">
                      <strong>{{ 'locations.details.ownerNumber'| translate }} {{i + 1}}:</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let ownerKey of ownersMap.get(key).keys()">
                    <tr>
                      <td>{{ 'locations.fieldNames.' + ownerKey | translate }}</td>
                      <td *ngIf="ownerKey == 'datePreliminary'">
                        {{ ownersMap.get(key).get(ownerKey) | date:'shortDate' }}
                      </td>
                      <td *ngIf="ownerKey != 'datePreliminary'">{{ getDetailValue(key, ownerKey, 'owner') }}</td>
                    </tr>
                  </ng-container>
                </ng-container>
                </tbody>
              </table>
            </div>
          </div>

          <strong *ngIf="plotsMap.size != 0">{{ 'locations.details.plots' | translate }}</strong>

          <div class="row" id="allPlotsTable" *ngIf="plotsMap.size != 0">

            <div class="col p-0">
              <table class="table">
                <thead class="thead-light">
                <tr>
                  <th scope="col">{{ 'locations.details.field' | translate }}</th>
                  <th scope="col">{{ 'locations.details.value' | translate }}</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let key of plotsMap.keys(); index as i">
                  <tr>
                    <td colspan="2">
                      <strong>{{ 'locations.details.plotNumber'| translate }}  {{i + 1}}:</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let plotKey of plotsMap.get(key).keys()">
                    <tr>
                      <td>{{ 'locations.fieldNames.' + plotKey | translate }}</td>
                      <td>{{ getDetailValue(key, plotKey, 'plot') }}</td>
                    </tr>
                  </ng-container>
                </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end traderForm__button">

          <button class="btn btn-secondary ml-2" (click)="onReturn()" *ngIf="stage!=1">
            {{ 'locations.add-location.return' | translate }}</button>

          <button class="btn btn-primary ml-2" (click)="onNext()" *ngIf="stage!=7">
            {{ 'locations.add-location.move' | translate }}
          </button>

          <button class="btn btn-primary ml-2" (click)="onSubmit()" *ngIf="stage==7 && !isEdit">
            {{ 'locations.add-location.add' | translate }}
          </button>

          <button class="btn btn-primary ml-2" (click)="onSubmit()" *ngIf="stage==7 && isEdit">
            {{ 'locations.edit' | translate }}
          </button>

        </div>
      </form>
    </div>
  </div>
  <div class="d-flex justify-content-center">
    <button class="btn btn-outline-danger" (click)="onCancel()">
      {{ 'locations.add-location.cancel' | translate }}
    </button>
  </div>
</div>

