import { Component, OnInit } from '@angular/core';
import { Location } from '../../model/location.model';
import { LocationHttpService } from '../../http/location-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { fieldNames } from '../locations.component';
import { Address } from '../../model/address.model';
import * as uuid from 'uuid';
import { Plot } from 'src/app/model/plot.model';
import { Owner } from 'src/app/model/owner.model';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MoreModalComponent } from './more-modal/more-modal.component';
import { UserHttpService } from '../../http/user-http.service';

@Component({
  selector: 'app-location-detail',
  templateUrl: './location-detail.component.html',
  styleUrls: ['./location-detail.component.scss'],
})
export class LocationDetailComponent implements OnInit {
  locationForm: FormGroup;
  addressForm: FormGroup;
  ownerForm: FormGroup;
  plotForm: FormGroup;

  location: Location = new Location();
  locationMap: Map<string, any> = new Map<string, any>();
  addressesMap: Map<string, any> = new Map<string, any>();
  ownersMap: Map<string, any> = new Map<string, any>();
  plotsMap: Map<string, any> = new Map<string, any>();
  allFields = [];

  isAddressForm = false;
  isPlotForm = false;
  isOwnerForm = false;

  constructor(
    private locationHttpService: LocationHttpService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private translate: TranslateService,
    private modalService: NgbModal,
    private userService: UserHttpService,
  ) {}

  ngOnInit(): void {
    this.initForm();

    const locationId = this.route.snapshot.paramMap.get('locationId');

    this.locationHttpService.getLocation(locationId).subscribe((location) => {
      this.location = location;
      this.setMaps();
    });
  }

  initForm() {
    this.locationForm = this.formBuilder.group({
      keyName: [null, [Validators.required]],
      newValue: [null, [Validators.required]],
    });

    this.addressForm = this.formBuilder.group({
      street: [null],
      number: [null],
      city: [null],
      state: [null],
    });

    this.ownerForm = this.formBuilder.group({
      name: [null],
      surname: [null],
      company: [null],
      address: [null],
      phone: [null],
      email: [null],
      isOwner: [null],
      isPreliminary: [null],
      datePreliminary: [null],
      isBroker: [null],
      isDirect: [null],
      isAgreement: [null],
      agreementTermsAk: [null],
      agreementTermsBroker: [null],
    });
    this.plotForm = this.formBuilder.group({
      pnumber: [null],
      territory: [null],
      precint: [null],
      kw: [null],
    });
  }

  get form() {
    return this.locationForm.controls;
  }

  get aForm() {
    return this.addressForm.controls;
  }

  get oForm() {
    return this.ownerForm.controls;
  }

  get pForm() {
    return this.plotForm.controls;
  }

  private setMaps() {
    let jsonObject = JSON.parse(JSON.stringify(this.location));

    for (var value in jsonObject) {
      if (!this.isAdmin() && !investorFieldsAlowance.includes(value)) {
        continue;
      }

      if (value != 'addresses' && value != 'owners' && value != 'plots' && value != 'id') {
        this.locationMap.set(value, jsonObject[value]);
        this.allFields.push(value);
      }
      if (value == 'plots') {
        for (var plot in jsonObject[value]) {
          if (!this.isAdmin()) {
            continue;
          }
          let plotMap = new Map<string, any>();
          for (var plotValue in jsonObject[value][plot]) {
            if (!this.isAdmin() && !investorFieldsAlowance.includes(plotValue)) {
              continue;
            }
            if (plotValue != 'id' && jsonObject[value][plot][plotValue] != null) {
              plotMap.set(plotValue, jsonObject[value][plot][plotValue]);
            }
          }
          this.plotsMap.set(jsonObject[value][plot]['id'], plotMap);
        }
      }
      if (value == 'addresses') {
        for (var address in jsonObject[value]) {
          let addressMap = new Map<string, any>();
          for (var addressValue in jsonObject[value][address]) {
            if (!this.isAdmin() && !investorFieldsAlowance.includes(addressValue)) {
              continue;
            }
            if (addressValue != 'id' && jsonObject[value][address][addressValue] != null) {
              addressMap.set(addressValue, jsonObject[value][address][addressValue]);
            }
          }
          this.addressesMap.set(jsonObject[value][address]['id'], addressMap);
        }
      }
      if (value == 'owners') {
        for (var owner in jsonObject[value]) {
          if (!this.isAdmin()) {
            continue;
          }
          let ownerMap = new Map<string, any>();
          for (var ownerValue in jsonObject[value][owner]) {
            if (ownerValue != 'id' && jsonObject[value][owner][ownerValue] != null) {
              ownerMap.set(ownerValue, jsonObject[value][owner][ownerValue]);
            }
          }
          this.ownersMap.set(jsonObject[value][owner]['id'], ownerMap);
        }
      }
    }
  }

  onSubmit() {
    this.location.owners.forEach((owner) => {
      let ngDateStruct: NgbDateStruct = owner.datePreliminary;
      // @ts-ignore
      const date: Date = new Date(ngDateStruct.year, ngDateStruct.month, ngDateStruct.day);
      owner.datePreliminary = date;
    });

    this.locationHttpService
      .updateLocation(this.location)
      .subscribe(() => this.router.navigate(['locations']));
  }

  onUpdate() {
    let jsonObject = JSON.parse(JSON.stringify(this.location));
    const keyName = this.locationForm.get('keyName').value;

    const isAddress = keyName.includes('Adres');
    const isPlot = keyName.includes('Działka');
    const isOwner = keyName.includes('Osoba');
    const isGeneral = !isAddress && !isPlot && !isOwner;

    if (isAddress) {
      const number = keyName.replace('Adres ', '').replace(':', '').charAt(0) - 1;
      const addressKeyName = keyName.replace('Adres ', '').replace(':', '').split(' ')[1];
      jsonObject['addresses'][number][addressKeyName] = this.locationForm.get('newValue').value;
    }
    if (isPlot) {
      const number = keyName.replace('Działka ', '').replace(':', '').charAt(0) - 1;
      const addressKeyName = keyName.replace('Działka ', '').replace(':', '').split(' ')[1];
      jsonObject['plots'][number][addressKeyName] = this.locationForm.get('newValue').value;
    }
    if (isOwner) {
      const number = keyName.replace('Osoba ', '').replace(':', '').charAt(0) - 1;
      const addressKeyName = keyName.replace('Osoba ', '').replace(':', '').split(' ')[1];
      if (addressKeyName != 'datePreliminary') {
        jsonObject['owners'][number][addressKeyName] = this.locationForm.get('newValue').value;
      }
    }
    if (isGeneral) {
      jsonObject[this.locationForm.get('keyName').value] = this.locationForm.get('newValue').value;
    }

    this.location = jsonObject;

    this.setMaps();
  }

  getFieldName(key: string) {
    const isAddress = key.includes('Adres');
    const isPlot = key.includes('Działka');
    const isOwner = key.includes('Osoba');
    const isGeneral = !isAddress && !isPlot && !isOwner;

    if (isGeneral) {
      return fieldNames.get(key);
    } else {
      if (isAddress) {
        const addressKeyName = key.replace('Adres ', '').replace(':', '').split(' ')[1];
        return key.replace(addressKeyName, fieldNames.get(addressKeyName));
      }
      if (isPlot) {
        const plotKeyName = key.replace('Działka ', '').replace(':', '').split(' ')[1];
        return key.replace(plotKeyName, fieldNames.get(plotKeyName));
      }
      if (isOwner) {
        const ownerKeyName = key.replace('Osoba ', '').replace(':', '').split(' ')[1];
        return key.replace(ownerKeyName, fieldNames.get(ownerKeyName));
      }
    }
  }

  isAdmin() {
    return localStorage.getItem('role') === 'ROLE_ADMIN';
  }

  onDeleteAddress(key: string) {
    this.location.addresses = this.location.addresses.filter((address) => {
      address.id != key;
    });
    this.addressesMap.delete(key);
  }

  onDeleteOwner(key: string) {
    this.location.owners = this.location.owners.filter((owner) => {
      owner.id != key;
    });
    this.ownersMap.delete(key);
  }

  onDeletePlot(key: string) {
    this.location.plots = this.location.plots.filter((plot) => {
      plot.id != key;
    });
    this.plotsMap.delete(key);
  }

  showAddressForm() {
    this.isAddressForm = !this.isAddressForm;
    this.isOwnerForm = false;
    this.isPlotForm = false;
  }

  showOwnerForm() {
    this.isAddressForm = false;
    this.isOwnerForm = !this.isOwnerForm;
    this.isPlotForm = false;
  }

  showPlotForm() {
    this.isAddressForm = false;
    this.isOwnerForm = false;
    this.isPlotForm = !this.isPlotForm;
  }

  onAddAddress() {
    const { state, city, street, number } = this.addressForm.value;
    const address = new Address(uuid.v4(), city, state, street, number);
    this.location.addresses.push(address);
    this.setMaps();
    this.showAddressForm();
  }

  onAddOwner() {
    const {
      name,
      surname,
      company,
      address,
      phone,
      email,
      isOwner,
      isPreliminary,
      datePreliminary,
      isBroker,
      isDirect,
      isAgreement,
      agreementTermsAk,
      agreementTermsBroker,
    } = this.ownerForm.value;

    const owner = new Owner(
      uuid.v4(),
      name,
      surname,
      company,
      address,
      phone,
      email,
      isOwner,
      isPreliminary,
      datePreliminary,
      isBroker,
      isDirect,
      isAgreement,
      agreementTermsAk,
      agreementTermsBroker,
    );
    console.log(owner);
    this.location.owners.push(owner);
    this.setMaps();
    this.showOwnerForm();
  }

  onAddPlot() {
    const { pnumber, territory, precint, kw } = this.plotForm.value;
    const plot = new Plot(uuid.v4(), pnumber, territory, precint, kw);
    this.location.plots.push(plot);
    this.setMaps();
    this.showPlotForm();
  }

  getGeneralValue(key: string) {
    let value = this.locationMap.get(key);

    if ((!selectionFields.includes(key) || value == null) && !(typeof value == 'boolean')) {
      return this.locationMap.get(key);
    } else {
      return this.translate.instant('locations.details.values.' + value);
    }
  }

  getDetailValue(key: string, detailKey: string, type: string) {
    let value;

    if (type === 'address') {
      value = this.addressesMap.get(key).get(detailKey);
    }
    if (type === 'owner') {
      value = this.ownersMap.get(key).get(detailKey);
    }
    if (type === 'plot') {
      value = this.plotsMap.get(key).get(detailKey);
    }

    if ((!selectionFields.includes(detailKey) || value == null) && !(typeof value == 'boolean')) {
      return value;
    } else {
      return this.translate.instant('locations.details.values.' + value);
    }
  }

  onReturn() {
    this.router.navigate(['/locations']);
  }

  onLearnMore() {
    this.userService.sendInterestMail(localStorage.getItem('id'), this.location.id).subscribe();
    const modalRef = this.modalService.open(MoreModalComponent, {
      centered: true,
    });
  }
}

export const selectionFields = [
  'ltype',
  'status',
  'statusMpzp',
  'wz',
  'pnb',
  'buildingStage',
  'statusExpandable',
  'tax',
  'statusWater',
  'statusGas',
  'statusElectricity',
  'statusCanalization',
];

export const investorFieldsAlowance = [
  'ltype',
  'status',
  'city',
  'state',
  'surface',
  'statusMpzp',
  'linkMpzp',
  'wz',
  'pnb',
  'mpzp',
  'buildingYear',
  'buildingStage',
  'pum',
  'puu',
  'puuOffice',
  'puuTrade',
  'surfaceCommon',
  'garageQuantity',
  'isWater',
  'isElectricity',
  'isGas',
  'isCanalization',
  'currencyNoi',
  'monthsWault',
  'lyield',
  'height',
  'minBiol',
  'isAccessible',
  'streetMain',
  'isRelicEvidence',
  'isRelicRegister',
  'isTenants',
  'tax',
  'addresses',
  'plots',
  'owners',
];
