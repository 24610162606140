import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { User } from '../model/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserHttpService {
  constructor(private http: HttpClient) {}

  createUser(user: User) {
    return this.http.post<Response>('api/users', user);
  }

  getUsers(page = 0) {
    return this.http.get('api/users', { params: new HttpParams().set('page', `${page}`) });
  }

  updateUser(activatedUser: User) {
    return this.http.put('api/users/' + activatedUser.id, activatedUser);
  }

  deleteUser(user: User) {
    return this.http.delete('api/users/' + user.id);
  }

  getUser(userId: string) {
    return this.http.get('api/users/' + userId);
  }

  resetPassword(email: string) {
    const request = new EmailRequest(email);

    return this.http.post('api/auth/reset-password', request);
  }

  setPassword(newpassword, token) {
    const request = new PasswordRequest(newpassword, token);

    return this.http.post('api/auth/change-password', request);
  }

  sendInterestMail(userId: string, locationId: string) {
    return this.http.get('api/users/interested', {
      params: new HttpParams().set('userId', userId).set('locationId', locationId),
    });
  }
}

class EmailRequest {
  public email: string;
  constructor(email: string) {
    this.email = email;
  }
}

class PasswordRequest {
  password: string;
  token: string;

  constructor(password: string, token: string) {
    this.password = password;
    this.token = token;
  }
}
