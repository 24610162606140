<nav
  class="navbar navbar-expand-custom justify-content-between"
  [ngClass]="{
    other:
      location.path() == '/locations' ||
      location.path() == '/locations/add' ||
      location.path() == '/admin' ||
      location.path() == '/login' ||
      location.path() == '/register' ||
      location.path() == '/reset-password' ||
      location.path().includes('failure') ||
      location.path().includes('success') ||
      location.path().includes('details') ||
      location.path().includes('career') ||
      location.path().includes('admin/user') ||
      location.path().includes('account') ||
      location.path().includes('locations/edit') ||
      location.path().includes('account')
  }"
>
  <a class="navbar-brand" (click)="onRedirect()">
    <img src="assets/logo_crop.png" alt="Asset Kingdom logo" />
    ASSET KINGDOM
  </a>

  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div
    #menuCollapse
    class="collapse navbar-collapse navbar-content"
    id="navbarSupportedContent"
    [ngClass]="{ darkBg: smallScreen }"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <a
          class="nav-link"
          routerLinkActive="active"
          href="#map-pos"
          menuClose
          [menu]="menuCollapse"
          *ngIf="!(isAdmin() || isInvestor())"
          >{{ 'nav.startSearching' | translate }}</a
        >
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          routerLinkActive="active"
          href="#solution-pos"
          menuClose
          [menu]="menuCollapse"
          *ngIf="!(isAdmin() || isInvestor())"
          >{{ 'nav.solution' | translate }}</a
        >
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          routerLinkActive="active"
          href="#clients-pos"
          menuClose
          [menu]="menuCollapse"
          *ngIf="!(isAdmin() || isInvestor())"
          >{{ 'nav.clients' | translate }}</a
        >
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          routerLinkActive="active"
          href="#about-pos"
          menuClose
          [menu]="menuCollapse"
          *ngIf="!(isAdmin() || isInvestor())"
          >{{ 'nav.about' | translate }}</a
        >
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          routerLinkActive="active"
          (click)="onCareer()"
          menuClose
          [menu]="menuCollapse"
          *ngIf="!(isAdmin() || isInvestor()) && this.language!='en'"
          >{{ 'nav.career' | translate }}</a
        >
      </li>

      <li class="nav-item">
        <a
          class="nav-link admin-link"
          routerLinkActive="active"
          routerLink="admin"
          menuClose
          [menu]="menuCollapse"
          *ngIf="isAdmin()"
          >{{ 'nav.users' | translate }}</a
        >
      </li>

      <li class="nav-item">
        <a
          class="nav-link admin-link"
          routerLinkActive="active"
          routerLink="locations"
          menuClose
          [menu]="menuCollapse"
          *ngIf="isAdmin() || isInvestor()"
          >{{ 'nav.base' | translate }}</a
        >
      </li>
    </ul>

    <ul class="navbar-nav">
      <li class="nav-item">
        <button
          class="btn btn-navbar mr-sm-2 font-light"
          menuClose
          [menu]="menuCollapse"
          (click)="onLogin()"
          *ngIf="!(isLoggedIn() || location.path() === '/login')"
        >
          {{ 'nav.login' | translate }}
        </button>
      </li>

      <li class="nav-item">
        <button
          class="btn btn-navbar mr-sm-2 font-light"
          menuClose
          [menu]="menuCollapse"
          (click)="onAccount() || location.path() === '/account'"
          *ngIf="isLoggedIn()"
        >
          {{ 'nav.account' | translate }}
        </button>
      </li>

      <li class="nav-item">
        <button
          class="btn btn-navbar mr-sm-2 font-light"
          menuClose
          [menu]="menuCollapse"
          (click)="onLogout()"
          *ngIf="isLoggedIn()"
        >
          {{ 'nav.logout' | translate }}
        </button>
      </li>

      <li class="nav-item">
        <button
          class="btn btn-navbar btn-primary btn-join btn-outline-light font-light mr-2"
          menuClose
          [menu]="menuCollapse"
          (click)="onRegister()"
          *ngIf="!(isLoggedIn() || location.path() === '/register')"
        >
          <span class="btn-text"> {{ 'nav.joinUs' | translate }}</span>
        </button>
      </li>

      <li class="nav-item language">
        <button
          *ngIf="language == 'en'"
          class="btn btn-navbar second-lg font-light"
          (click)="onSwitchLanguage()"
        >
          PL
        </button>
        <button
          *ngIf="language == 'pl'"
          class="btn btn-navbar second-lg font-light"
          (click)="onSwitchLanguage()"
        >
          EN
        </button>
      </li>
    </ul>
  </div>
</nav>
