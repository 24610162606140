<div class="container p-5 justify-content-center">
  <div class="row mt-5 pt-5">
    <div class="col-12">
      <h2><b> {{ 'register.title' | translate }} <span id="logo">Asset Kingdom</span> </b></h2>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <p> {{ 'register.description' | translate }} </p>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-6" id="message">
      <p><strong>{{ 'register.success.first' | translate }} </strong></p>

      <p>{{ 'register.success.second' | translate }}<b>
        {{ 'register.success.third' | translate }}
      </b>
        {{ 'register.success.fourth' | translate }}</p>
    </div>

  </div>
</div>
