<div class="container-fluid pt-20">
    <div class="details-container">
      <div class="d-flex justify-content-between mb-3">
        <h4>
          <strong>{{ 'locations.details.general' | translate }}</strong>
        </h4>
        <div class="buttons">
          <button class="btn btn-info btn-learn-more ml-2" (click)="onLearnMore()">
            {{ 'locations.details.learnMore' | translate }}
          </button>
          <button class="btn btn-info btn-return ml-2" (click)="onReturn()">
            {{ 'locations.details.return' | translate }}
          </button>
        </div>
      </div>
      <div class="" id="allDataTable">
        <div class=" p-0">
          <table class="table">
            <thead class="thead-light">
            <tr>
              <th scope="col">{{ 'locations.details.field' | translate }}</th>
              <th scope="col">{{ 'locations.details.value' | translate }}</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let key of locationMap.keys()">
              <tr>
                <td>{{ 'locations.fieldNames.' + key | translate }}</td>
                <td>{{ getGeneralValue(key) }}</td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>
      </div>

      <h4>
        <strong *ngIf="addressesMap.size != 0">{{ 'locations.details.addresses' | translate }}</strong>
      </h4>

      <div class="" id="allAdressesTable" *ngIf="addressesMap.size != 0">
        <div class=" p-0">
          <table class="table">
            <thead class="thead-light">
            <tr>
              <th scope="col">{{ 'locations.details.field' | translate }}</th>
              <th scope="col">{{ 'locations.details.value' | translate }}</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let key of addressesMap.keys(); index as i">
              <tr>
                <td colspan="2">
                  <strong>{{ 'locations.details.addressNumber' | translate }} {{i + 1}}:</strong>
                </td>
              </tr>
              <ng-container *ngFor="let addressKey of addressesMap.get(key).keys()">
                <tr>
                  <td>{{ 'locations.fieldNames.' + addressKey | translate }}</td>
                  <td>{{ getDetailValue(key, addressKey, 'address') }}</td>
                </tr>
              </ng-container>
            </ng-container>
            </tbody>
          </table>
        </div>
      </div>

      <h4>
        <strong *ngIf="ownersMap.size != 0">
          {{ 'locations.details.owners' | translate }}
        </strong>
      </h4>

      <div class="" id="allOwnersTable" *ngIf="ownersMap.size != 0">
        <div class=" p-0">
          <table class="table">
            <thead class="thead-light">
            <tr>
              <th scope="col"> {{ 'locations.details.field' | translate }}</th>
              <th scope="col"> {{ 'locations.details.value' | translate }}</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let key of ownersMap.keys(); index as i">
              <tr>
                <td colspan="2">
                  <strong>{{ 'locations.details.ownerNumber' | translate }} {{i + 1}}:</strong>
                </td>
              </tr>
              <ng-container *ngFor="let ownerKey of ownersMap.get(key).keys()">
                <tr>
                  <td>{{ 'locations.fieldNames.' + ownerKey | translate }}</td>
                  <td
                    *ngIf="ownerKey=='datePreliminary'">{{  ownersMap.get(key).get(ownerKey) | date:'shortDate' }}</td>
                  <td *ngIf="ownerKey!='datePreliminary'">{{ getDetailValue(key, ownerKey, 'owner') }}</td>
                </tr>
              </ng-container>
            </ng-container>
            </tbody>
          </table>
        </div>
      </div>

      <h4>
        <strong *ngIf="plotsMap.size != 0"> {{ 'locations.details.plots' | translate }}</strong>
      </h4>

      <div class="" id="allPlotsTable" *ngIf="plotsMap.size != 0">

        <div class=" p-0">
          <table class="table">
            <thead class="thead-light">
            <tr>
              <th scope="col"> {{ 'locations.details.field' | translate }}</th>
              <th scope="col"> {{ 'locations.details.value' | translate }}</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let key of plotsMap.keys(); index as i">
              <tr>
                <td colspan="2">
                  <strong>{{'locations.details.plotNumber' | translate }} {{i + 1}}:</strong>
                </td>
              </tr>
              <ng-container *ngFor="let plotKey of plotsMap.get(key).keys()">
                <tr>
                  <td>{{ 'locations.fieldNames.' + plotKey | translate }}</td>
                  <td>{{ getDetailValue(key, plotKey, 'plot')}}</td>
                </tr>
              </ng-container>
            </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>

</div>

