<img class="top-img" src="../../assets/img/nav_short/px11_short2.jpg" alt="">
<div class="overlay">
</div>

<div class="container mt-5">
  <div class="row">
    <div class="col-12 center">
      <h2 class="mb-4">
        {{ 'login.title' | translate }}
        <span id="logo">
          Asset Kingdom
        </span></h2>
    </div>
  </div>

  <div class="row">
    <div class="col-12 center">
      <p>
        {{ 'login.description' | translate }}
      </p>
    </div>
  </div>
  <div class="row-flex mt-5 mb-5">
    <div class="col-40">

      <form #authForm="ngForm" (ngSubmit)="onSubmit(authForm)" class="d-flex flex-column ">

        <div class="row  input-row authForm center">
          <div class="form-group">
            <label for="email" class="font-weight-bold">E-Mail</label>
            <input type="email" id="email" class="form-control" ngModel name="email" email required #email="ngModel"
              [ngClass]="{ 'is-invalid': email.invalid && email.touched}" />
            <div class="invalid-feedback" *ngIf="email.invalid && email.touched">
              {{ 'login.wrongEmail' | translate }}
            </div>
          </div>
        </div>
        <div class="row  mt-0 input-row authForm center">
          <div class="form-group ">
            <label for="password" class="font-weight-bold">
              {{ 'login.password' | translate }}
            </label>
            <input type="password" id="password" class="form-control" ngModel name="password" minlength="6" required
              #password="ngModel" [ngClass]="{ 'is-invalid': password.invalid && password.touched}" />
            <div class="invalid-feedback" *ngIf="password.invalid && password.touched">
              {{ 'login.wrongPassword' | translate }}
            </div>
          </div>
        </div>


        <div class="row captcha center">
          <div class="">
            <re-captcha (resolved)="resolved($event)" (error)="onError($event)" errorMode="handled"
              siteKey="6LeMTe4ZAAAAABjOYaORGhJAlsW2m7LZD8hzmAUT">
            </re-captcha>
          </div>
        </div>

        <div class="row center">
          <div class="">
            <button class="btn btn-primary mt-3 " type="submit">
              {{ 'login.login' | translate }}
            </button>
          </div>
        </div>
      </form>


      <div class="row center mt-3">
        <p>
          {{ 'login.noAccount' | translate }}
          <a routerLink="register" (click)="onJoinUs()" class="text-pri">
            {{ 'login.joinUs' | translate }}
          </a></p>
      </div>
      <div class="row center">
        <a routerLink="" (click)="onForgotPassword()" class="text-pri align-self-end">
          {{ 'login.forgotPassword' | translate }}
        </a>
      </div>
    </div>
    <div class="col-60">
      <img class="illustration" src="../../assets/img/ilustrations/undraw_Login_re_4vu22.svg" alt="">
    </div>
  </div>

</div>
<app-footer></app-footer>
