<section class="bg" id="solution-pos">
  <div class="container-fluid">
    <h2 class="font-weight-bold">
      {{ 'solution.title.first' | translate
      }}<span class="best"> {{ 'solution.title.second' | translate }}</span>
    </h2>
    <p>
      {{ 'solution.description' | translate }}
    </p>

    <div class="steps">
      <div class="step-container">
        <div class="img-container">
          <img
            src="../../assets/img/ilustrations/undraw_Collaboration_primary.svg"
            alt="Zostań członkiem"
          />
        </div>
        <div class="step step-lg no-lm">
          <h3 class="step-title">
            {{ 'solution.step-1.title' | translate }}
          </h3>
          <div class="step-description">
            <p class="step-header best">
              <strong>
                {{ 'solution.step-1.header-1' | translate }}
              </strong>
            </p>
            <p class="step-text">
              {{ 'solution.step-1.description-1' | translate }}
            </p>
            <p class="step-header best">
              <strong>
                {{ 'solution.step-1.header-2' | translate }}
              </strong>
            </p>
            <p class="step-text">
              {{ 'solution.step-1.description-2' | translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="step-container">
        <div class="img-container">
          <img
            src="../../assets/img/ilustrations/undraw_Progress_overview_primary.svg"
            alt="Zostań członkiem"
          />
        </div>
        <div class="step step-lg">
          <h3 class="step-title">
            {{ 'solution.step-2.title' | translate }}
          </h3>
          <div class="step-description">
            <p class="step-header best">
              <strong>
                {{ 'solution.step-2.header-1' | translate }}
              </strong>
            </p>
            <p class="step-text">
              {{ 'solution.step-2.description-1' | translate }}
            </p>
            <p class="step-header best">
              <strong>
                {{ 'solution.step-2.header-2' | translate }}
              </strong>
            </p>
            <p class="step-text">
              {{ 'solution.step-2.description-2' | translate }}
            </p>
          </div>
        </div>
      </div>

      <div class="step-container">
        <div class="img-container">
          <img
            src="../../assets/img/ilustrations/undraw_Terms_primary.svg"
            alt="Zostań członkiem"
          />
        </div>
        <div class="step step-lg no-rm">
          <h3 class="step-title">
            {{ 'solution.step-3.title' | translate }}
          </h3>
          <div class="step-description">
            <p class="step-header best">
              <strong>
                {{ 'solution.step-3.header-1' | translate }}
              </strong>
            </p>
            <p class="step-text">
              {{ 'solution.step-3.description-1' | translate }}
            </p>
            <p class="step-header best">
              <strong>
                {{ 'solution.step-3.header-2' | translate }}
              </strong>
            </p>
            <p class="step-text">
              {{ 'solution.step-3.description-2' | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
