<section class="bg" id="about-pos">
  <div class="container-fluid about-container pr-5">
    <div class="logo-container w-100 d-flex flex-wrap">
      <img src="../../assets/logo_crop.png" alt="logo" id="logo" class="logo" />
      <h1>ASSET KINGDOM</h1>
    </div>

    <h2 class="font-weight-bold">
      {{ 'about.title-1.first' | translate }}
      <span class="best">
        {{ 'about.title-1.second' | translate }}
      </span>
    </h2>
    <p>
      {{ 'about.description-1' | translate }}
    </p>

    <h2 class="font-weight-bold">
      {{ 'about.title-2.first' | translate
      }}<span class="best">
        {{ 'about.title-2.second' | translate }}
      </span>
    </h2>
    <p>
      {{ 'about.description-2.first' | translate }}
    </p>
    <ol>
      <li>
        {{ 'about.description-2.second' | translate }}
      </li>
      <li>{{ 'about.description-2.third' | translate }} <br /></li>
      <li>
        {{ 'about.description-2.fourth' | translate }}
      </li>
      <li>{{ 'about.description-2.fifth' | translate }}</li>
    </ol>
  </div>
</section>
