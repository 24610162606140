import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserHttpService } from '../../http/user-http.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  resetForm: FormGroup;

  constructor(
    private router: Router,
    private userService: UserHttpService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  onSubmit() {
    console.log(this.resetForm.value);
    this.userService
      .resetPassword(this.resetForm.get('email').value)
      .subscribe(() => this.router.navigate(['login']));
  }

  private initForm() {
    this.resetForm = this.formBuilder.group({
      email: [null, [Validators.required]],
    });
  }
}
