import { Component, OnDestroy, OnInit } from '@angular/core';
import { Role, User } from '../../model/user.model';
import { UserHttpService } from '../../http/user-http.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit, OnDestroy {
  user: User = null;
  userForm: FormGroup;
  rolesSelection: Map<string, string> = new Map();
  adminSubscrition: Subscription;
  ownerSubscrition: Subscription;
  investorSubscrition: Subscription;
  brokerSubscrition: Subscription;
  loading = true;

  constructor(
    private userService: UserHttpService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const userId = this.route.snapshot.paramMap.get('userId');

    this.userService.getUser(userId).subscribe((data) => {
      // @ts-ignore
      this.user = data;
      this.initForm();
      this.subscribeRolesSelection();
      this.loading = false;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeRolesSelection();
  }

  get form() {
    return this.userForm.controls;
  }

  private initForm() {
    this.userForm = this.formBuilder.group({
      name: [this.user.name, [Validators.required]],
      surname: [this.user.surname, [Validators.required]],
      company: [this.user.company, [Validators.required]],
      phone: [this.user.phone, [Validators.required]],
      email: [this.user.email, [Validators.required]],
      role: [null, [Validators.required]],
    });
  }

  onSubmit() {
    this.user.name = this.userForm.get('name').value;
    this.user.surname = this.userForm.get('surname').value;
    this.user.company = this.userForm.get('company').value;
    this.user.phone = this.userForm.get('phone').value;
    this.user.email = this.userForm.get('email').value;
    if (this.userForm.get('role').value != null) {
      this.user.roles = [new Role(null, this.userForm.get('role').value)];
    }

    this.userService.updateUser(this.user).subscribe(() => this.router.navigate(['admin']));
  }

  subscribeRolesSelection() {
    this.adminSubscrition = this.translate
      .stream('roles.admin')
      .subscribe((translation) => this.rolesSelection.set('ROLE_ADMIN', translation));
    this.ownerSubscrition = this.translate
      .stream('roles.owner')
      .subscribe((translation) => this.rolesSelection.set('ROLE_OWNER', translation));
    this.investorSubscrition = this.translate
      .stream('roles.investor')
      .subscribe((translation) => this.rolesSelection.set('ROLE_INVESTOR', translation));
    this.brokerSubscrition = this.translate
      .stream('roles.broker')
      .subscribe((translation) => this.rolesSelection.set('ROLE_BROKER', translation));
  }

  unsubscribeRolesSelection() {
    this.adminSubscrition.unsubscribe();
    this.ownerSubscrition.unsubscribe();
    this.investorSubscrition.unsubscribe();
    this.brokerSubscrition.unsubscribe();
  }
}
