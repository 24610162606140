import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit {
  // images = [
  // "../../assets/img/building1.jpg",
  // "../../assets/img/building2.jpg",
  // "../../assets/img/building3.jpg",
  // "../../assets/img/building4.jpg",
  // "../../assets/img/family.jpg",
  // "../../assets/img/wiezowiecedit.jpg",
  // "../../assets/img/paris3.jpg",
  // "../../assets/img/sydney.jpg"];

  images = [
    '../../assets/img/px1.jpg',
    '../../assets/img/px4_ver2.jpg',
    '../../assets/img/px14.jpg',
    '../../assets/img/px3.jpg',
    '../../assets/img/px8.jpg',
    '../../assets/img/px7.jpg',
    '../../assets/img/px10.jpg',
    '../../assets/img/px13ver2.jpg',
  ];

  constructor(config: NgbCarouselConfig) {
    config.interval = 4000; ///5000
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = false;
    config.showNavigationIndicators = false;
    config.showNavigationArrows = false;
  }

  ngOnInit(): void {}
}
