import { Component, OnInit } from '@angular/core';
import { User } from '../model/user.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserHttpService } from '../http/user-http.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
  user: User = null;
  userForm: FormGroup;
  loading = true;

  constructor(
    private userService: UserHttpService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const userId = localStorage.getItem('id');

    this.userService.getUser(userId).subscribe((data) => {
      // @ts-ignore
      this.user = data;
      this.initForm();
      this.loading = false;
    });
  }

  get form() {
    return this.userForm.controls;
  }

  initForm() {
    this.userForm = this.formBuilder.group({
      name: [this.user.name, [Validators.required]],
      surname: [this.user.surname, [Validators.required]],
      company: [this.user.company, [Validators.required]],
      phone: [this.user.phone, [Validators.required]],
      email: [this.user.email, [Validators.required]],
      newsletter: ['', [Validators.required]],
    });
  }

  onSubmit() {
    if (this.userForm.valid) {
      const { name, company, email, phone, surname, newsletter } = this.userForm.value;

      this.user.name = name;
      this.user.surname = surname;
      this.user.company = company;
      this.user.phone = phone;
      this.user.email = email;
      this.user.newsletter = newsletter;

      this.userService.updateUser(this.user).subscribe(() => this.router.navigate(['']));
    }
  }

  onDelete() {
    this.userService.deleteUser(this.user).subscribe(() => {
      localStorage.clear();
      this.router.navigate(['login']);
    });
  }
}
