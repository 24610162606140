<div class="carousel-container">
    <ngb-carousel>
        <ng-template ngbSlide>
            <img src={{images[0]}} alt="Building 1">

        </ng-template>
        <ng-template ngbSlide>
            <img src={{images[1]}} alt="Building 2">

        </ng-template>

        <ng-template ngbSlide>
            <img src={{images[2]}} alt="Building 4">

        </ng-template>
        <ng-template ngbSlide>
            <img src={{images[3]}} alt="Building 5">
        </ng-template>
        
        <ng-template ngbSlide>
            <img src={{images[4]}} alt="Building 1">

        </ng-template>
        <ng-template ngbSlide>
            <img src={{images[5]}} alt="Building 2">

        </ng-template>

        <ng-template ngbSlide>
            <img src={{images[6]}} alt="Building 4">

        </ng-template>
        <ng-template ngbSlide>
            <img src={{images[7]}} alt="Building 5">
        </ng-template>



    </ngb-carousel>
</div>