<header class="showcase">
  <app-carousel></app-carousel>
  <div class="overlay">
    <div class="container-fluid showcase-container">
      <h2 class="showcase-title">
        {{ 'home.showcase.title.first' | translate }} <br /><span class="tool">
          {{ 'home.showcase.title.second' | translate }}</span
        >
        <br />
        {{ 'home.showcase.title.third' | translate }}
      </h2>
      <p class="showcase-description">
        <strong>Asset Kingdom</strong>
        {{ 'home.showcase.description.first' | translate
        }}<strong> {{ 'home.showcase.description.second' | translate }} </strong>
        {{ 'home.showcase.description.third' | translate
        }}<strong> {{ 'home.showcase.description.fourth' | translate }}</strong>
        {{ 'home.showcase.description.fifth' | translate
        }}<strong> {{ 'home.showcase.description.sixth' | translate }}</strong
        >.
      </p>
      <a [routerLink]="['/register']">
        <div class="btn-primary showcase-btn">
          {{ 'home.showcase.startSearching' | translate }}
        </div>
      </a>
      <p class="showcase-small">
        {{ 'home.showcase.usingAlready' | translate }}
        <a class="showcase-link" [routerLink]="['/login']">
          {{ 'home.showcase.login' | translate }}
        </a>
      </p>
    </div>
  </div>
  <div class="arrow-container">
    <a href="#map-pos">
      <button class="move-down">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          class="bi bi-caret-down-fill"
          viewBox="0 0 16 16"
        >
          <path
            d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
          />
        </svg>
      </button>
    </a>
  </div>
</header>

<app-map></app-map>
<app-solution></app-solution>
<app-clients></app-clients>
<app-about></app-about>
<app-footer></app-footer>
