import { LocationWz } from './../../model/location/location-wz';
import { LocationSurface } from './../../model/location/location-surface.model';
import { LocationPuu } from './../../model/location/location-puu';
import { LocationPum } from './../../model/location/location-pum';
import { LocationPnb } from './../../model/location/location-pnb';
import { LocationMpzp } from './../../model/location/location-mpzp.model';
import { Location } from 'src/app/model/location.model';
import { LocationCity } from 'src/app/model/location/location-city';
import { LocationLType } from 'src/app/model/location/location-ltype.model';
import { LocationStatus } from 'src/app/model/location/location-status.model';

export class LocationFilter {
  constructor(private locations: Location[]) {}

  filterByCity(cities: string[]) {
    if (cities && cities.length > 0) {
      this.locations = this.locations.filter((location) => cities.includes(location.city));
    }
    return this;
  }

  filterByLType(ltype: LocationLType) {
    if (!this.isObjectEmpty(ltype)) {
      const keysWithTrueValue: string[] = this.getKeysForTrueValue(ltype);
      this.locations = this.locations.filter((location) =>
        keysWithTrueValue.includes(location.ltype),
      );
    }
    return this;
  }

  filterByMpzp(mpzp: LocationMpzp) {
    if (!this.isObjectEmpty(mpzp)) {
      const keysWithTrueValue: string[] = this.getKeysForTrueValue(mpzp);
      this.locations = this.locations.filter((location) =>
        keysWithTrueValue.includes(location.statusMpzp),
      );
    }
    return this;
  }

  filterByPnb(pnb: LocationPnb) {
    if (!this.isObjectEmpty(pnb)) {
      const keysWithTrueValue: string[] = this.getKeysForTrueValue(pnb);
      this.locations = this.locations.filter((location) =>
        keysWithTrueValue.includes(location.pnb),
      );
    }
    return this;
  }

  filterByPum(pum: LocationPum) {
    if (!this.isObjectEmpty(pum)) {
      this.locations = this.locations.filter((location) =>
        this.isInRange(pum.from, pum.to, location.pum),
      );
    }
    return this;
  }

  filterByPuu(puu: LocationPuu) {
    if (!this.isObjectEmpty(puu)) {
      this.locations = this.locations.filter((location) =>
        this.isInRange(puu.from, puu.to, location.puu),
      );
    }
    return this;
  }

  filterByStatus(status: LocationStatus) {
    if (!this.isObjectEmpty(status)) {
      const keysWithTrueValue: string[] = this.getKeysForTrueValue(status);
      this.locations = this.locations.filter((location) =>
        keysWithTrueValue.includes(location.status),
      );
    }
    return this;
  }

  filterBySurface(surface: LocationSurface) {
    if (!this.isObjectEmpty(surface)) {
      this.locations = this.locations.filter((location) =>
        this.isInRange(surface.from, surface.to, location.surface),
      );
    }
    return this;
  }

  filterByWz(wz: LocationWz) {
    if (!this.isObjectEmpty(wz)) {
      const keysWithTrueValue: string[] = this.getKeysForTrueValue(wz);
      this.locations = this.locations.filter((location) => keysWithTrueValue.includes(location.wz));
    }
    return this;
  }

  getFilteredLocations(): Location[] {
    return this.locations;
  }

  private isObjectEmpty(obj): boolean {
    let isEmpty = true;
    for (let key in obj) {
      if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
        isEmpty = false;
      }
    }
    return isEmpty;
  }

  private isInRange(from: number, to: number, value: number) {
    return value >= from && value <= to;
  }

  private getKeysForTrueValue(obj): string[] {
    const keys = Object.keys(obj);
    return keys.filter((key) => obj[key]);
  }
}
