import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  @Output() public changeLanguage: EventEmitter<void> = new EventEmitter<void>();

  private curLocation = '';
  public language = 'pl';
  public smallScreen = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    public location: Location,
  ) {
    this.loadNav();
  }

  ngOnInit(): void {}

  loadNav(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      if (this.location.path() == '/locations') this.curLocation = this.location.path();

      const includesRegister = this.location.path().includes('register');
      const includesLogin = this.location.path().includes('login');
      if (window.innerWidth < 1200 && (includesRegister || includesLogin)) {
        this.smallScreen = true;
      } else {
        this.smallScreen = false;
      }
    });
  }

  onRedirect() {
    this.router.navigate(['/']);
    window.scroll(0, 0);
  }

  onLogin() {
    this.router.navigate(['login']);
  }

  onRegister() {
    this.router.navigate(['register']);
  }

  onSwitchLanguage() {
    if (this.language === 'pl') {
      localStorage.setItem('language', 'en');
      this.language = 'en';
    } else {
      localStorage.setItem('language', 'pl');
      this.language = 'pl';
    }
    this.changeLanguage.emit();

    if (this.location.path().includes('career')) {
      this.router.navigate(['/']);
    }
  }

  onLogout() {
    this.authService.logout();
    this.router.navigate(['']);
  }

  isLoggedIn() {
    return localStorage.getItem('id') != null;
  }

  isAdmin() {
    return localStorage.getItem('role') == 'ROLE_ADMIN';
  }

  isInvestor() {
    return localStorage.getItem('role') == 'ROLE_INVESTOR';
  }

  onCareer() {
    this.router.navigate(['career']);
  }

  onAccount() {
    this.router.navigate(['account']);
  }
}
