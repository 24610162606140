export class User {
  constructor(
    public id: string,
    public name: string,
    public surname: string,
    public company: string,
    public phone: string,
    public email: string,
    public password: string,
    public roles: Role[],
    public newsletter: boolean,
    public activated: boolean,
  ) {}
}

export class Role {
  constructor(public id: string, public name: string) {}
}
