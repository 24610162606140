import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss'],
})
export class CookiesComponent implements OnInit {
  @Input() public isCookieHidden: boolean;
  @Output() public hide: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onHide() {
    sessionStorage.setItem('cookies', 'true');
    this.hide.emit();
  }

  onFindOut() {
    var win = window.open('assets/privacy-policy.pdf', '_blank');
    win.focus();
  }
}
