<section class="location-filter my-5">
  <div class="btn btn-outline-secondary" (click)="toggleForm()">
    <i class="bi-filter"></i>
  </div>
  <div class="container mt-3" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
    <form [formGroup]="filterForm" (ngSubmit)="onSubmit()">
      <!-- Typ nieruchomosci -->
      <div class="form-group">
        <label for="ltype" class="font-weight-light">
          {{ 'locations.fieldNames.ltype' | translate }}
        </label>
        <ng-select id="ltype" [multiple]="false" [clearable]="false" formControlName="ltype">
          <ng-option *ngFor="let type of locationTypes" [value]="type">{{
            'locations.add-location.selections.ltype.' + type | translate
          }}</ng-option>
        </ng-select>
      </div>
      <!-- status -->
      <div class="form-group" formGroupName="status">
        <h2 class="h6">{{ 'locations.fieldNames.status' | translate }}</h2>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="existing" />
          <label class="form-check-label">
            {{ 'locations.add-location.selections.status.existing' | translate }}
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="toBuild" />
          <label class="form-check-label">
            {{ 'locations.add-location.selections.status.toBuild' | translate }}
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="toExpand" />
          <label class="form-check-label">
            {{ 'locations.add-location.selections.status.toExpand' | translate }}
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="toRenovation" />
          <label class="form-check-label">
            {{ 'locations.add-location.selections.status.toRenovation' | translate }}
          </label>
        </div>
      </div>

      <!-- powierzchnia -->
      <h2 class="font-weight-light h6">
        {{ 'locations.fieldNames.surface' | translate }}
      </h2>
      <div class="d-flex align-items-center" formGroupName="surface">
        <div class="form-group mr-3">
          <input
            type="number"
            min="0"
            class="form-control"
            placeholder="{{ 'locations.filter.from' | translate }}"
            formControlName="from"
          />
        </div>
        <div class="form-group">
          <input type="number" min="0" class="form-control" placeholder="{{ 'locations.filter.to' | translate }}" formControlName="to" />
        </div>
      </div>

      <h2 class="font-weight-light h6">
        {{ 'locations.fieldNames.puu' | translate }}
      </h2>
      <div class="d-flex align-items-center" formGroupName="puu">
        <div class="form-group mr-3">
          <input
            type="number"
            min="0"
            class="form-control"
            placeholder="{{ 'locations.filter.from' | translate }}"
            formControlName="from"
          />
        </div>
        <div class="form-group">
          <input type="number" min="0" class="form-control" placeholder="{{ 'locations.filter.to' | translate }}" formControlName="to" />
        </div>
      </div>

      <h2 class="font-weight-light h6">
        {{ 'locations.fieldNames.pum' | translate }}
      </h2>
      <div class="d-flex align-items-center" formGroupName="pum">
        <div class="form-group mr-3">
          <input
            type="number"
            min="0"
            class="form-control"
            placeholder="{{ 'locations.filter.from' | translate }}"
            formControlName="from"
          />
        </div>
        <div class="form-group">
          <input type="number" min="0" class="form-control" placeholder="{{ 'locations.filter.to' | translate }}" formControlName="to" />
        </div>
      </div>

      <!-- miasto -->
      <div class="form-group">
        <h2 class="h6">{{ 'locations.fieldNames.city' | translate }}</h2>
        <ng-select [multiple]="true" [(ngModel)]="selectedCity" formControlName="cities">
          <ng-option *ngFor="let city of cities" [value]="city.name">{{ city.name }}</ng-option>
        </ng-select>
      </div>

      <!-- mpzp -->
      <div class="form-group" formGroupName="mpzp">
        <h2 class="h6">{{ 'locations.fieldNames.statusMpzp' | translate }}</h2>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="yes" />
          <label class="form-check-label">
            {{ 'locations.add-location.selections.mpzp.yes' | translate }}
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="no" />
          <label class="form-check-label">
            {{ 'locations.add-location.selections.mpzp.no' | translate }}
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="atEnact" />
          <label class="form-check-label">
            {{ 'locations.add-location.selections.mpzp.atEnact' | translate }}
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="atChange" />
          <label class="form-check-label">
            {{ 'locations.add-location.selections.mpzp.atChange' | translate }}
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="anulled" />
          <label class="form-check-label">
            {{ 'locations.add-location.selections.mpzp.anulled' | translate }}
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="studium" />
          <label class="form-check-label">
            {{ 'locations.add-location.selections.mpzp.studium' | translate }}
          </label>
        </div>
      </div>

      <!-- wz -->
      <div class="form-group" formGroupName="wz">
        <h2 class="h6">{{ 'locations.fieldNames.wz' | translate }}</h2>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="yes" />
          <label class="form-check-label">
            {{ 'locations.add-location.selections.wz.yes' | translate }}
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="no" />
          <label class="form-check-label">
            {{ 'locations.add-location.selections.wz.no' | translate }}
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="atObtaining" />
          <label class="form-check-label">
            {{ 'locations.add-location.selections.wz.atObtaining' | translate }}
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="atCancellation" />
          <label class="form-check-label">
            {{ 'locations.add-location.selections.wz.atCancellation' | translate }}
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="atVoivode" />
          <label class="form-check-label">
            {{ 'locations.add-location.selections.wz.atVoivode' | translate }}
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="atSko" />
          <label class="form-check-label">
            {{ 'locations.add-location.selections.wz.atSko' | translate }}
          </label>
        </div>
      </div>

      <!-- pnb -->
      <div class="form-group" formGroupName="pnb">
        <h2 class="h6">{{ 'locations.fieldNames.pnb' | translate }}</h2>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="yes" />
          <label class="form-check-label">
            {{ 'locations.add-location.selections.pnb.yes' | translate }}
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="no" />
          <label class="form-check-label">
            {{ 'locations.add-location.selections.pnb.no' | translate }}
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="atObtaining" />
          <label class="form-check-label">
            {{ 'locations.add-location.selections.pnb.atObtaining' | translate }}
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="atCancellation" />
          <label class="form-check-label">
            {{ 'locations.add-location.selections.pnb.atCancellation' | translate }}
          </label>
        </div>
      </div>
      <button class="btn btn-primary" type="submit">Filtruj</button>
    </form>
  </div>
</section>
