export class Owner {
  constructor(
    public id: string,
    public name: string,
    public surname: number,
    public company: number,
    public address: string,
    public phone: string,
    public email: string,
    public isOwner: boolean,
    public isPreliminary: boolean,
    public datePreliminary: any,
    public isBroker: boolean,
    public isDirect: boolean,
    public isAgreement: boolean,
    public agreementTermsAk: number,
    public agreementTermsBroker: string,
  ) {}
}
