import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { BehaviorSubject, throwError } from 'rxjs';
import { User } from './user.model';

interface AuthResponseData {
  token: string;
  type: string;
  id: number;
  username: string;
  email: string;
  roles: string[];
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  user = new BehaviorSubject<User>(null);
  isUserLoggedIn = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
    this.checkLoginStatus();
  }

  login(email: string, password: string) {
    return this.http
      .post<AuthResponseData>(`api/auth/login`, {
        email: email,
        password: password,
      })
      .pipe(
        catchError((errorRes) => {
          if (!errorRes.error || !errorRes.error.error) {
            return throwError('An unknown error occurred!');
          }
          return throwError('Incorrect login details were provided!');
        }),
        tap((resData) => {
          localStorage.setItem('id', resData.id.toString());
          localStorage.setItem('username', resData.username);
          localStorage.setItem('role', resData.roles[0]);
          this.handleAuthentication(resData.username, resData.token, resData.roles[0]);
          this.checkLoginStatus();
        }),
      );
  }

  getUserRole(): string {
    return localStorage.getItem('role');
  }

  checkLoginStatus(): void {
    if (localStorage.getItem('id')) {
      this.isUserLoggedIn.next(true);
    } else {
      this.isUserLoggedIn.next(false);
    }
  }

  autoLogin() {
    const userData: {
      username: string;
      _token: string;
    } = JSON.parse(localStorage.getItem('userData'));
    const role = localStorage.getItem('role');

    if (!userData) {
      return;
    }
    const loadedUser = new User(userData.username, userData._token, role);

    if (loadedUser.token) {
      this.user.next(loadedUser);
    }
  }

  logout() {
    this.user.next(null);
    localStorage.removeItem('userData');
    localStorage.clear();
    this.checkLoginStatus();
  }

  private handleAuthentication(username: string, token: string, role: string) {
    const user = new User(username, token, role);
    this.user.next(user);
    localStorage.setItem('userData', JSON.stringify(user));
  }

  resetPassword(email) {
    return this.http
      .post('api/auth/reset-password', {
        username: email,
      })
      .pipe(
        catchError((errorRes) => {
          return throwError(errorRes);
        }),
      );
  }
}
