import { Injectable, OnInit } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Location } from '../model/location.model';
import { Observable } from 'rxjs';
import { LocationCity } from '../model/location/location-city';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LocationHttpService {
  private http: HttpClient;
  baseUrl = 'https://assetkingdom.pl/api/locations';

  constructor(handler: HttpBackend, private router: Router) {
    this.http = new HttpClient(handler);
  }

  getLocations(): Observable<Location[]> {
    return this.http.get<Location[]>(this.baseUrl);
  }

  getLocation(id) {
    return this.http.get(this.baseUrl + '/' + id);
  }

  updateLocation(location: Location) {
    return this.http.put(this.baseUrl + '/' + location.id, location);
  }

  deleteLocation(id) {
    return this.http.delete(this.baseUrl + '/' + id);
  }

  getAllLocationCities(): Observable<LocationCity[]> {
    const citiesUrl = `${this.baseUrl}/cities`;
    return this.http.get<LocationCity[]>(citiesUrl);
  }

  addLocation(location: Location) {
    this.getCoords(location.city).subscribe((cityData) => {
      // @ts-ignore
      location.longitude = cityData[0].lon;
      // @ts-ignore
      location.latitude = cityData[0].lat;

      this.http.post(this.baseUrl, location).subscribe(() => {
        this.router.navigate(['locations']);
      });
    });
  }

  private getCoords(city: string) {
    const url =
      'https://nominatim.openstreetmap.org/search?city=' +
      city +
      '&format=json&addressdetails=1&limit=1&polygon_svg=1&accept-language=pl';
    return this.http.get(url);
  }
}
