<section #careerContainer id="career-pos">
  <div class="container-fluid">
    <h2 class="font-weight-bold">
      {{ 'career.title.first' | translate }}
      <span class="best">
        {{ 'career.title.second' | translate }}
      </span>
    </h2>
    <p>
      {{ 'career.description.first' | translate }}
      <br /><br />
      {{ 'career.description.second' | translate }}
    </p>
    <div class="jobs-container">
      <section class="job-offer">
        <div class="job-content">
          <div class="job-header">
            <h4 class="">{{ 'career.position' | translate }}</h4>
            <h3 class="second font-weight-bold">{{ 'career.job-1.title' | translate }}</h3>
          </div>
          <div class="duties-wrapper">
            <p>{{ 'career.mainDuties' | translate }}</p>
            <ol class="">
              <li class="duty">{{ 'career.job-1.duties.first' | translate }}</li>
              <li class="duty">{{ 'career.job-1.duties.second' | translate }}</li>
              <li class="duty">{{ 'career.job-1.duties.third' | translate }}</li>
              <li class="duty">{{ 'career.job-1.duties.fourth' | translate }}</li>
            </ol>
          </div>
        </div>
        <div class="btn-wrapper">
          <button class="btn-primary join-us">
            <a href="mailto:info@assetkingdom.pl?subject=Rekrutacja">{{ 'career.joinUs' | translate }}</a>
          </button>
        </div>
      </section>
      <section class="job-offer">
        <div class="job-content">
          <div class="job-header">
            <h4 class="">{{ 'career.position' | translate }}</h4>
            <h3 class="second font-weight-bold">{{ 'career.job-2.title' | translate }}</h3>
          </div>

          <div class="duties-wrapper">
            <p>{{ 'career.mainDuties' | translate }}</p>
            <ol class="">
              <li class="duty">{{ 'career.job-2.duties.first' | translate }}</li>
              <li class="duty">{{ 'career.job-2.duties.second' | translate }}</li>
              <li class="duty">{{ 'career.job-2.duties.third' | translate }}</li>
            </ol>
          </div>
        </div>

        <div class="btn-wrapper">
          <button class="btn-primary join-us">
            <a href="mailto:info@assetkingdom.pl?subject=Rekrutacja">{{ 'career.joinUs' | translate }}</a>
          </button>
        </div>
      </section>
    </div>
  </div>
</section>
<app-footer></app-footer>
