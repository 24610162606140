import { Component, OnDestroy, OnInit } from '@angular/core';
import { LocationHttpService } from '../../http/location-http.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '../../model/location.model';
import { Address } from '../../model/address.model';
import { Owner } from '../../model/owner.model';
import { v4 as uuidv4 } from 'uuid';
import { Plot } from 'src/app/model/plot.model';
import { Observable, Subscribable, Subscription } from 'rxjs';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { fieldNames } from '../locations.component';
import { TranslateService } from '@ngx-translate/core';
import { Route } from '@angular/compiler/src/core';
import { selectionFields } from '../location-detail/location-detail.component';

@Component({
  selector: 'app-add-location',
  templateUrl: './add-location.component.html',
  styleUrls: ['./add-location.component.scss'],
})
export class AddLocationComponent implements OnInit, OnDestroy {
  locationForm: FormGroup;
  stage = 1;
  location: Location = new Location();
  locationMap: Map<string, any> = new Map<string, any>();
  addressesMap: Map<string, any> = new Map<string, any>();
  ownersMap: Map<string, any> = new Map<string, any>();
  plotsMap: Map<string, any> = new Map<string, any>();

  typesSelection: Map<string, string> = new Map<string, string>();
  statusesSelection: Map<string, string> = new Map<string, string>();
  mpzpSelection: Map<string, string> = new Map<string, string>();
  expandapleSelection: Map<string, string> = new Map<string, string>();
  buildingStageSelection: Map<string, string> = new Map<string, string>();
  pnbSelection: Map<string, string> = new Map<string, string>();
  wzSelection: Map<string, string> = new Map<string, string>();
  mediaSelection: Map<string, string> = new Map<string, string>();
  taxSelection: Map<string, string> = new Map<string, string>();

  tax$: Subscription;
  media$: Subscription;
  expandable$: Subscription;
  buildingStage$: Subscription;
  pnb$: Subscription;
  wz$: Subscription;
  mpzp$: Subscription;
  statuses$: Subscription;
  types$: Subscription;
  isEdit: boolean = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private locationHttpService: LocationHttpService,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private translate: TranslateService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    const locationId = this.route.snapshot.paramMap.get('locationId');

    if (locationId != null) {
      this.locationHttpService.getLocation(locationId).subscribe((location) => {
        this.location = location;
        this.initForm();
        this.insertSelects();
        this.isEdit = true;
      });
    } else {
      this.initForm();
      this.insertSelects();
    }
  }

  ngOnDestroy(): void {
    this.tax$.unsubscribe();
    this.media$.unsubscribe();
    this.expandable$.unsubscribe();
    this.buildingStage$.unsubscribe();
    this.pnb$.unsubscribe();
    this.wz$.unsubscribe();
    this.mpzp$.unsubscribe();
    this.statuses$.unsubscribe();
    this.types$.unsubscribe();
  }

  private initForm() {
    this.locationForm = this.formBuilder.group({
      // Stage 1: general information
      lname: [this.location.name, [Validators.required]],
      address: [null, [Validators.required]],
      lcity: [this.location.city, [Validators.required]],
      ltype: [this.location.ltype, [Validators.required]],
      status: [this.location.status, [Validators.required]],
      // Stage 2: address properties:
      state: [null],
      city: [this.location.city],
      street: [null],
      number: [null],
      // Stage 3: location details:
      statusMpzp: [this.location.statusMpzp],
      mpzp: [this.location.mpzp],
      linkMpzp: [this.location.linkMpzp],
      wz: [this.location.wz],
      pnb: [this.location.pnb],
      linkGoogle: [this.location.linkGoogle],
      linkGeoportal: [this.location.linkGeoportal],
      surface: [this.location.surface],
      isBuyAll: [this.location.isBuyAll],
      buildingYear: [this.location.buildingYear],
      buildingQuantity: [this.location.buildingQuantity],
      buildingContignationUnderground: [this.location.buildingContignationUnderground],
      buildingContignationGround: [this.location.buildingContignationGround],
      buildingStage: [this.location.buildingStage],
      statusExpandable: [this.location.statusExpandable],
      pum: [this.location.pum],
      puu: [this.location.puu],
      puuOffice: [this.location.puuOffice],
      puuTrade: [this.location.puuTrade],
      surfaceCommon: [this.location.surfaceCommon],
      price: [this.location.price],
      currency: [this.location.currency],
      pricePum: [this.location.pricePum],
      pricePuu: [this.location.pricePuu],
      isGarage: [this.location.isGarage],
      isGarageGround: [this.location.isGarageGround],
      garageGroundFloors: [this.location.garageGroundFloors],
      garageGroundQuantity: [this.location.garageGroundQuantity],
      garageGroundSurface: [this.location.garageGroundSurface],
      isGarageUnderground: [this.location.isGarageUnderground],
      isGarageUndergroundPlatforms: [this.location.isGarageUndergroundPlatforms],
      garageUndergroundFloors: [this.location.garageUndergroundFloors],
      garageUndergroundQuantity: [this.location.garageUndergroundQuantity],
      garageUndergroundSurface: [this.location.garageUndergroundSurface],
      garageQuantity: [this.location.garageGroundQuantity],
      garageSurface: [this.location.garageSurface],
      garageBikes: [this.location.garageBikes],
      statusElectricity: [this.location.statusElectricity],
      statusWater: [this.location.statusWater],
      statusCanalization: [this.location.statusCanalization],
      statusGas: [this.location.statusGas],
      currencyNoi: [this.location.currencyNoi],
      currencyGoi: [this.location.currencyGoi],
      monthsWault: [this.location.monthsWault],
      lyield: [this.location.lyield],
      certificates: [this.location.certificates],
      height: [this.location.height],
      streetMain: [this.location.streetMain],
      minBiol: [this.location.minBiol],
      isExitroad: [this.location.isExitroad],
      isRelicEvidence: [this.location.isRelicEvidence],
      isRelicRegister: [this.location.isRelicRegister],
      isTenants: [this.location.isTenants],
      transactionTypes: [this.location.transactionTypes],
      transactionTerms: [this.location.transactionTerms],
      nature: [this.location.nature],
      isAccessible: [this.location.isAccessible],
      other: [this.location.other],
      tax: [this.location.tax],
      servitude: [this.location.servitude],
      // Stage 4: owners properties:
      name: [null],
      surname: [null],
      company: [null],
      phone: [null],
      email: [null],
      isOwner: [null],
      isPreliminary: [null],
      datePreliminary: [null],
      isBroker: [null],
      isDirect: [null],
      isAgreement: [null],
      agreementTermsAk: [null],
      agreementTermsBroker: [null],
      // Stage 5: plots properties:
      pnumber: [null],
      territory: [null],
      precint: [null],
      kw: [null],
    });
  }

  get form() {
    return this.locationForm.controls;
  }

  onSubmit() {
    if (!this.isEdit) {
      let subscribe = this.setLocation().then(() => {
        this.locationHttpService.addLocation(this.location);
      });
    }
    if (this.isEdit) {
      let subscribe = this.setLocation().then(() => {
        this.locationHttpService
          .updateLocation(this.location)
          .subscribe(() => this.router.navigate(['locations']));
      });
    }
  }

  onReturn() {
    this.stage = this.stage - 1;
  }

  onNext() {
    this.stage = this.stage + 1;
    if (this.stage == 7) {
      this.setLocation().then(() => {
        this.setMaps();
      });
    }
  }

  onDeleteAddress(address: Address) {
    this.location.addresses = this.location.addresses.filter((laddress) => laddress !== address);
  }

  onAddAddress() {
    this.location.addresses.push(
      new Address(
        uuidv4().toString(),
        this.locationForm.get('city').value,
        this.locationForm.get('state').value,
        this.locationForm.get('street').value,
        this.locationForm.get('number').value,
      ),
    );
  }

  onDeleteOwner(owner: Owner) {
    this.location.owners = this.location.owners.filter((lowner) => lowner !== owner);
  }

  onAddOwner() {
    this.location.owners.push(
      new Owner(
        uuidv4().toString(),
        this.locationForm.get('name').value,
        this.locationForm.get('surname').value,
        this.locationForm.get('company').value,
        this.locationForm.get('address').value,
        this.locationForm.get('phone').value,
        this.locationForm.get('email').value,
        this.locationForm.get('isOwner').value,
        this.locationForm.get('isPreliminary').value,
        new Date(
          this.ngbDateParserFormatter.format(this.locationForm.get('datePreliminary').value),
        ),
        this.locationForm.get('isBroker').value,
        this.locationForm.get('isDirect').value,
        this.locationForm.get('isAgreement').value,
        this.locationForm.get('agreementTermsAk').value,
        this.locationForm.get('agreementTermsBroker').value,
      ),
    );
  }

  onAddPlot() {
    this.location.plots.push(
      new Plot(
        uuidv4().toString(),
        this.locationForm.get('pnumber').value,
        this.locationForm.get('territory').value,
        this.locationForm.get('precint').value,
        this.locationForm.get('kw').value,
      ),
    );
  }

  onDeletePlot(plot: Plot) {
    this.location.plots = this.location.plots.filter((lplot) => lplot !== plot);
  }

  private setLocation() {
    return new Promise((resolve) => {
      this.location.name = this.locationForm.get('lname').value;
      this.location.ltype = this.locationForm.get('ltype').value;
      this.location.city = this.locationForm.get('lcity').value;
      this.location.status = this.locationForm.get('status').value;
      this.location.statusMpzp = this.locationForm.get('statusMpzp').value;
      this.location.mpzp = this.locationForm.get('mpzp').value;
      this.location.linkMpzp = this.locationForm.get('linkMpzp').value;
      this.location.wz = this.locationForm.get('wz').value;
      this.location.pnb = this.locationForm.get('pnb').value;
      this.location.linkGoogle = this.locationForm.get('linkGoogle').value;
      this.location.linkGeoportal = this.locationForm.get('linkGeoportal').value;
      this.location.surface = this.locationForm.get('surface').value;
      this.location.isBuyAll = this.locationForm.get('isBuyAll').value;
      this.location.buildingYear = this.locationForm.get('buildingYear').value;
      this.location.buildingQuantity = this.locationForm.get('buildingQuantity').value;
      this.location.buildingContignationUnderground = this.locationForm.get(
        'buildingContignationUnderground',
      ).value;
      this.location.buildingContignationGround = this.locationForm.get(
        'buildingContignationGround',
      ).value;
      this.location.buildingStage = this.locationForm.get('buildingStage').value;
      this.location.statusExpandable = this.locationForm.get('statusExpandable').value;
      this.location.pum = this.locationForm.get('pum').value;
      this.location.puu = this.locationForm.get('puu').value;
      this.location.puuOffice = this.locationForm.get('puuOffice').value;
      this.location.puuTrade = this.locationForm.get('puuTrade').value;
      this.location.surfaceCommon = this.locationForm.get('surfaceCommon').value;
      this.location.price = this.locationForm.get('price').value;
      this.location.currency = this.locationForm.get('currency').value;
      this.location.pricePum = this.locationForm.get('pricePum').value;
      this.location.pricePuu = this.locationForm.get('pricePuu').value;
      this.location.isGarage = this.locationForm.get('isGarage').value;
      this.location.isGarageGround = this.locationForm.get('isGarageGround').value;
      this.location.garageGroundFloors = this.locationForm.get('garageGroundFloors').value;
      this.location.garageGroundQuantity = this.locationForm.get('garageGroundQuantity').value;
      this.location.garageGroundSurface = this.locationForm.get('garageGroundSurface').value;
      this.location.isGarageUnderground = this.locationForm.get('isGarageUnderground').value;
      this.location.isGarageUndergroundPlatforms = this.locationForm.get(
        'isGarageUndergroundPlatforms',
      ).value;
      this.location.garageUndergroundFloors = this.locationForm.get(
        'garageUndergroundFloors',
      ).value;
      this.location.garageUndergroundQuantity = this.locationForm.get(
        'garageUndergroundQuantity',
      ).value;
      this.location.garageUndergroundSurface = this.locationForm.get('name').value;
      this.location.garageQuantity = this.locationForm.get('garageQuantity').value;
      this.location.garageSurface = this.locationForm.get('garageSurface').value;
      this.location.garageBikes = this.locationForm.get('garageBikes').value;
      this.location.statusElectricity = this.locationForm.get('statusElectricity').value;
      this.location.statusWater = this.locationForm.get('statusWater').value;
      this.location.statusCanalization = this.locationForm.get('statusCanalization').value;
      this.location.statusGas = this.locationForm.get('statusGas').value;
      this.location.currencyNoi = this.locationForm.get('currencyNoi').value;
      this.location.currencyGoi = this.locationForm.get('currencyGoi').value;
      this.location.monthsWault = this.locationForm.get('monthsWault').value;
      this.location.lyield = this.locationForm.get('lyield').value;
      this.location.certificates = this.locationForm.get('certificates').value;
      this.location.height = this.locationForm.get('height').value;
      this.location.streetMain = this.locationForm.get('streetMain').value;
      this.location.minBiol = this.locationForm.get('minBiol').value;
      this.location.isExitroad = this.locationForm.get('isExitroad').value;
      this.location.isRelicEvidence = this.locationForm.get('isRelicEvidence').value;
      this.location.isRelicRegister = this.locationForm.get('isRelicRegister').value;
      this.location.isTenants = this.locationForm.get('isTenants').value;
      this.location.transactionTypes = this.locationForm.get('transactionTypes').value;
      this.location.transactionTerms = this.locationForm.get('transactionTerms').value;
      this.location.nature = this.locationForm.get('nature').value;
      this.location.isAccessible = this.locationForm.get('isAccessible').value;
      this.location.other = this.locationForm.get('other').value;
      this.location.tax = this.locationForm.get('tax').value;
      this.location.servitude = this.locationForm.get('servitude').value;
      resolve();
    });
  }

  private setMaps() {
    let jsonObject = JSON.parse(JSON.stringify(this.location));

    for (var value in jsonObject) {
      if (
        jsonObject[value] != null &&
        value != 'addresses' &&
        value != 'owners' &&
        value != 'plots' &&
        value != 'id'
      ) {
        this.locationMap.set(value, jsonObject[value]);
      }
      if (value == 'plots') {
        for (var plot in jsonObject[value]) {
          let plotMap = new Map<string, any>();
          for (var plotValue in jsonObject[value][plot]) {
            if (plotValue != 'id' && jsonObject[value][plot][plotValue] != null) {
              plotMap.set(plotValue, jsonObject[value][plot][plotValue]);
            }
          }
          this.plotsMap.set(jsonObject[value][plot]['id'], plotMap);
        }
      }
      if (value == 'addresses') {
        for (var address in jsonObject[value]) {
          let addressMap = new Map<string, any>();
          for (var addressValue in jsonObject[value][address]) {
            if (addressValue != 'id' && jsonObject[value][address][addressValue] != null) {
              addressMap.set(addressValue, jsonObject[value][address][addressValue]);
            }
          }
          this.addressesMap.set(jsonObject[value][address]['id'], addressMap);
        }
      }
      if (value == 'owners') {
        for (var owner in jsonObject[value]) {
          let ownerMap = new Map<string, any>();
          for (var ownerValue in jsonObject[value][owner]) {
            if (ownerValue != 'id' && jsonObject[value][owner][ownerValue] != null) {
              ownerMap.set(ownerValue, jsonObject[value][owner][ownerValue]);
            }
          }
          this.ownersMap.set(jsonObject[value][owner]['id'], ownerMap);
        }
      }
    }
  }

  private insertSelects() {
    this.insertTypesSelection();
    this.insertStatusSelection();
    this.insertMpzpSelection();
    this.insertBuildingStageSelection();
    this.insertWzSelection();
    this.insertPnbSelection();
    this.insertExpandableSelection();
    this.insertMediaSelection();
    this.insertTaxSelection();
  }

  private insertTypesSelection() {
    this.types$ = this.translate
      .stream('locations.add-location.selections.ltype')
      .subscribe((data) => {
        for (var value in data) {
          this.typesSelection.set(value, data[value]);
        }
      });
  }

  private insertStatusSelection() {
    this.statuses$ = this.translate
      .stream('locations.add-location.selections.status')
      .subscribe((data) => {
        for (var value in data) {
          this.statusesSelection.set(value, data[value]);
        }
      });
  }

  private insertMpzpSelection() {
    this.mpzp$ = this.translate
      .stream('locations.add-location.selections.mpzp')
      .subscribe((data) => {
        for (var value in data) {
          this.mpzpSelection.set(value, data[value]);
        }
      });
  }

  private insertWzSelection() {
    this.wz$ = this.translate.stream('locations.add-location.selections.wz').subscribe((data) => {
      for (var value in data) {
        this.wzSelection.set(value, data[value]);
      }
    });
  }

  private insertPnbSelection() {
    this.pnb$ = this.translate
      .stream('locations.add-location.selections.mpzp')
      .subscribe((data) => {
        for (var value in data) {
          this.pnbSelection.set(value, data[value]);
        }
      });
  }

  private insertBuildingStageSelection() {
    this.buildingStage$ = this.translate
      .stream('locations.add-location.selections.buildingStage')
      .subscribe((data) => {
        for (var value in data) {
          this.buildingStageSelection.set(value, data[value]);
        }
      });
  }

  private insertExpandableSelection() {
    this.expandable$ = this.translate
      .stream('locations.add-location.selections.expandable')
      .subscribe((data) => {
        for (var value in data) {
          this.expandapleSelection.set(value, data[value]);
        }
      });
  }

  private insertMediaSelection() {
    this.media$ = this.translate
      .stream('locations.add-location.selections.media')
      .subscribe((data) => {
        for (var value in data) {
          this.mediaSelection.set(value, data[value]);
        }
      });
  }

  private insertTaxSelection() {
    this.tax$ = this.translate.stream('locations.add-location.selections.tax').subscribe((data) => {
      for (var value in data) {
        this.taxSelection.set(value, data[value]);
      }
    });
  }

  getFieldName(key: string) {
    return fieldNames.get(key);
  }

  getGeneralValue(key: string) {
    let value = this.locationMap.get(key);

    if ((!selectionFields.includes(key) || value == null) && !(typeof value == 'boolean')) {
      return this.locationMap.get(key);
    } else {
      return this.translate.instant('locations.details.values.' + value);
    }
  }

  getDetailValue(key: string, detailKey: string, type: string) {
    let value;

    if (type === 'address') {
      value = this.addressesMap.get(key).get(detailKey);
    }
    if (type === 'owner') {
      value = this.ownersMap.get(key).get(detailKey);
    }
    if (type === 'plot') {
      value = this.plotsMap.get(key).get(detailKey);
    }

    if ((!selectionFields.includes(detailKey) || value == null) && !(typeof value == 'boolean')) {
      return value;
    } else {
      return this.translate.instant('locations.details.values.' + value);
    }
  }

  onCancel() {
    this.router.navigate(['/locations']);
  }
}
