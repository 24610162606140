import { LocationMpzp } from './../../model/location/location-mpzp.model';
import { LocationPuu } from './../../model/location/location-puu';
import { LocationSurface } from './../../model/location/location-surface.model';
import { LocationStatus } from './../../model/location/location-status.model';
import { LocationLType } from './../../model/location/location-ltype.model';
import { LocationHttpService } from './../../http/location-http.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Location } from 'src/app/model/location.model';
import { LocationCity } from 'src/app/model/location/location-city';
import { TranslateService } from '@ngx-translate/core';
import { LocationPum } from 'src/app/model/location/location-pum';
import { LocationWz } from 'src/app/model/location/location-wz';
import { LocationPnb } from 'src/app/model/location/location-pnb';
import { LocationFilter } from './location-filter';

@Component({
  selector: 'app-location-filter',
  templateUrl: './location-filter.component.html',
  styleUrls: ['./location-filter.component.scss'],
})
export class LocationFilterComponent implements OnInit {
  @Output('locationsFilter')
  public locationsChange: EventEmitter<Location[]> = new EventEmitter();

  isCollapsed = true;
  locationTypes: string[];
  locations: Location[];
  cities: LocationCity[];
  selectedCity = [];
  filterForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private locationService: LocationHttpService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.initFilterForm();
    this.getLocationTypes();
    this.getLocations();
    this.getCities();
  }

  get form() {
    return this.filterForm;
  }

  toggleForm() {
    this.isCollapsed = !this.isCollapsed;
  }

  onSubmit() {
    if (this.filterForm.valid) {
      const { ltype, status, surface, puu, pum, cities, mpzp, wz, pnb } = this.filterForm.value;

      const locationType: LocationLType = {};
      if (ltype) {
        locationType[ltype] = true;
      }

      const { existing, toBuild, toExpand, toRenovation } = status;
      const locationStatus: LocationStatus = {
        existing,
        toBuild,
        toExpand,
        toRenovation,
      };

      const locationSurface: LocationSurface = {
        from: surface.from,
        to: surface.to,
      };

      const locationPuu: LocationPuu = {
        from: puu.from,
        to: puu.to,
      };

      const locationPum: LocationPum = {
        from: pum.from,
        to: pum.to,
      };

      const { yes, no, atEnact, atChange, anulled, studium } = mpzp;
      const locationMpzp: LocationMpzp = {
        yes,
        no,
        atEnact,
        atChange,
        anulled,
        studium,
      };

      const { atObtaining, atCancellation, atVoivode, atSko } = wz;
      const locationWz: LocationWz = {
        yes: wz.yes,
        no: wz.no,
        atObtaining,
        atCancellation,
        atVoivode,
        atSko,
      };

      const locationPnb: LocationPnb = {
        yes: pnb.yes,
        no: pnb.no,
        atCancellation: pnb.atCancellation,
        atObtaining: pnb.atObtaining,
      };

      const locationFilter = new LocationFilter(this.locations);
      const filteredLocations = locationFilter
        .filterByCity(cities)
        .filterByLType(locationType)
        .filterByMpzp(locationMpzp)
        .filterByPnb(locationPnb)
        .filterByPum(locationPum)
        .filterByPuu(locationPuu)
        .filterByStatus(locationStatus)
        .filterBySurface(locationSurface)
        .filterByWz(locationWz)
        .getFilteredLocations();

      this.locationsChange.emit(filteredLocations);
      this.filterForm.reset();
    }
  }

  private initFilterForm() {
    this.filterForm = this.fb.group({
      ltype: [''],
      status: this.fb.group({
        existing: [''],
        toBuild: [''],
        toExpand: [''],
        toRenovation: [''],
      }),
      surface: this.fb.group({
        from: [''],
        to: [''],
      }),
      puu: this.fb.group({
        from: [''],
        to: [''],
      }),
      pum: this.fb.group({
        from: [''],
        to: [''],
      }),
      cities: [''],
      mpzp: this.fb.group({
        yes: [''],
        no: [''],
        atEnact: [''],
        atChange: [''],
        anulled: [''],
        studium: [''],
      }),
      wz: this.fb.group({
        yes: [''],
        no: [''],
        atObtaining: [''],
        atCancellation: [''],
        atVoivode: [''],
        atSko: [''],
      }),
      pnb: this.fb.group({
        yes: [''],
        no: [''],
        atObtaining: [''],
        atCancellation: [''],
      }),
    });
  }

  private getLocationTypes() {
    this.translateService.get('locations.add-location.selections.ltype').subscribe((data) => {
      this.locationTypes = Object.keys(data);
    });
  }

  private getLocations(): void {
    this.locationService.getLocations().subscribe((locations) => {
      this.locations = locations;
      console.log(locations);
    });
  }

  private getCities() {
    this.locationService.getAllLocationCities().subscribe((cities) => {
      this.cities = cities;
    });
  }
}
