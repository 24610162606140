<div class="modal-header">
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>{{ 'locations.details.learnMorePopup' | translate }}</p>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.dismiss('Cancel click')"
  >
    {{ 'login.modal.return' | translate }}
  </button>
</div>
