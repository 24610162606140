<div class="container-fluid p-5">
  <div class="row mb-3 p-5">
    <div class="col-4">
      <h4 class="mb-0 font-weight-bold" style="margin-top: 20px">
        {{ 'investors.header' | translate }}
      </h4>
    </div>
  </div>
  <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
  <div class="row" *ngIf="!isLoading">
    <div class="col p-0">
      <table class="table">
        <thead class="thead-light">
        <tr>
          <th scope="col">No.</th>
          <th scope="col">   {{ 'investors.role' | translate }} </th>
          <th scope="col">   {{ 'investors.name' | translate }}</th>
          <th scope="col">   {{ 'investors.lastname' | translate }}</th>
          <th scope="col">   {{ 'investors.company' | translate }}</th>
          <th scope="col">   {{ 'investors.phone' | translate }}</th>
          <th scope="col">   {{ 'investors.email' | translate }}</th>
          <th scope="col">   {{ 'investors.newsletter' | translate }}</th>
          <th scope="col">   {{ 'investors.activity' | translate }}</th>
          <th scope="col">   {{ 'investors.activate' | translate }}</th>
          <th scope="col">   {{ 'investors.edit' | translate }}</th>
          <th scope="col">   {{ 'investors.delete' | translate }}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let user of users; index as i">
          <th scope="row" class="font-weight-bold">{{ i+1 }}</th>
          <td><b>{{ 'roles.'+user.roles[0].name | translate }}</b></td>
          <td>{{ user.name }}</td>
          <td>{{ user.surname }}</td>
          <td>{{ user.company }}</td>
          <td>{{ user.phone }}</td>
          <td>{{ user.email }}</td>
          <td>
            <span *ngIf="user.newsletter" style='font-size:30px;'>&#10004;</span>
            <span *ngIf="!user.newsletter" style='font-size:30px;'>&#10008;</span>
          </td>
          <td>
            <span *ngIf="user.activated" style='font-size:30px;'>&#10004;</span>
            <span *ngIf="!user.activated" style='font-size:30px;' >&#10008;</span>
          </td>
          <td style="width: 10%">
          <button
            name="activate"
            type="button"
            class="btn btn-join"
            *ngIf="!isActivated(user)"
            (click)="onActivate(user)"
          >
            {{ 'investors.activate' | translate }}
          </button>
            <button
              name="activate"
              type="button"
              class="btn btn-danger"
              *ngIf="isActivated(user)"
              (click)="onDeactivate(user)"
            >
              {{ 'investors.deactivate' | translate }}
            </button>
          </td>

          <td>
            <button class="btn btn-info" (click)="onEdit(user)">
              {{ 'investors.edit' | translate }}
            </button>
          </td>

          <td>
            <button
              name="delete"
              type="button"
              class="btn btn-outline-danger"
              (click)="onDelete(user)"
            >
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="bi bi-trash-fill"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"
                />
              </svg>
            </button>
          </td>

        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
