import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserHttpService } from '../../http/user-http.service';

@Component({
  selector: 'app-set-new-password',
  templateUrl: './set-new-password.component.html',
  styleUrls: ['./set-new-password.component.scss'],
})
export class SetNewPasswordComponent implements OnInit {
  token: string;
  passwordForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private userService: UserHttpService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.route.queryParams.subscribe((params) => {
      this.token = params.token;
    });
  }

  private initForm() {
    this.passwordForm = this.formBuilder.group({
      password: [null, Validators.required],
      confirmPassword: [null, Validators.required],
    });
  }

  get form() {
    return this.passwordForm.controls;
  }

  onSubmit() {
    let { password, confirmPassword } = this.passwordForm.value;

    if (password != confirmPassword) {
      return;
    }

    this.userService
      .setPassword(this.passwordForm.get('password').value, this.token)
      .subscribe(() => this.router.navigate(['login']));
  }
}
