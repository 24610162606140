<img class="top-img" src="../../../assets/img/nav_short/px11_short2.jpg" alt="">
<div class="overlay">
</div>

<div class="container mt-5">
  <div class="row">
    <div class="col-12 center">
      <h2 class="mb-4">{{ 'reset.title' | translate }} <span id="logo">Asset Kingdom</span></h2>
    </div>
  </div>

  <div class="row">
    <div class="col-12 center">
      <p>{{ 'reset.set-new' | translate }}</p>
    </div>
  </div>

  <form [formGroup]="passwordForm" class="d-flex flex-column">
    <div class="row">
      <div class="col-4 offset-4 center input-row">
        <div class="form-group">
          <label for="password" class="font-weight-bold">{{ 'reset.password' | translate }}</label>
          <input
            type="password"
            id="password"
            class="form-control"
            formControlName="password"
            name="password"
            required
          />
        </div>
      </div>
      <div class="col-4 offset-4 center input-row">
        <div class="form-group">
          <label for="confirmPassword" class="font-weight-bold">{{ 'reset.confirmPassword' | translate }}</label>
          <input
            type="password"
            id="confirmPassword"
            class="form-control"
            formControlName="confirmPassword"
            name="confirmPassword"
            required
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 offset-6 center">
        <button class="btn btn-primary mt-3" (click)="onSubmit()" type="submit">
          {{ 'reset.button' | translate }}
        </button>
      </div>
    </div>

  </form>

</div>
