<div class="geometric-shape">
  <!-- <img
    class="geometric-image geometric-image-clients"
    src="../../assets/img/shapes/shape_complex.svg"
  /> -->
</div>
<section id="clients-pos">
  <div class="container-fluid">
    <h2 class="font-weight-bold">
      {{ 'clients.title.first' | translate }}
      <span class="best">
        {{ 'clients.title.second' | translate }}
      </span>
    </h2>
    <p>
      {{ 'clients.description' | translate }}
    </p>
    <div class="steps">
      <div class="step-container">
        <div class="img-container">
          <img
            src="../../assets/img/ilustrations/undraw_building_blocks_primary.svg"
            alt="Zostań członkiem"
          />
        </div>
        <div class="step step-sm no-lm">
          <h3 class="step-title">
            {{ 'clients.step-1.title' | translate }}
          </h3>

          <div class="step-description">
            <p class="p-h">
              {{ 'clients.step-1.header' | translate }}
            </p>
            <p class="step-text">
              {{ 'clients.step-1.description' | translate }}
            </p>
            <i class="fas fa-business-time"></i>
          </div>
        </div>
      </div>

      <div class="step-container">
        <div class="img-container">
          <img src="../../assets/img/ilustrations/undraw_city_girl.svg" alt="Zostań członkiem" />
        </div>
        <div class="step step-sm">
          <h3 class="step-title smaller">
            {{ 'clients.step-2.title' | translate }}
          </h3>

          <div class="step-description">
            <p class="p-h">
              {{ 'clients.step-2.header' | translate }}
            </p>
            <p class="step-text">
              {{ 'clients.step-2.description' | translate }}
            </p>
          </div>
        </div>
      </div>

      <div class="step-container">
        <div class="img-container">
          <img
            src="../../assets/img/ilustrations/undraw_team_spirit_primary.svg"
            alt="Zostań członkiem"
          />
        </div>
        <div class="step step-sm no-rm">
          <h3 class="step-title">
            {{ 'clients.step-3.title' | translate }}
          </h3>

          <div class="step-description">
            <p class="p-h">
              {{ 'clients.step-3.header' | translate }}
            </p>
            <p class="step-text">
              {{ 'clients.step-3.description' | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
