<img class="top-img" src="../../assets/img/nav_short/px11_short2.jpg" alt="">
<div class="overlay">
</div>
<div class="container justify-content-center mb-5">
  <div class="row">
    <div class="col-12 pt-5">
      <h2><b>
        {{ 'register.title' | translate }} <span id="logo">
        Asset Kingdom</span> </b></h2>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <p>
        {{ 'register.description' | translate }}
      </p>
    </div>
  </div>

  <form [formGroup]="userForm" (ngSubmit)="onSubmit()" class="row-flex">
    <div class="w-5">
      <div class="row">
        <div class="col-6 userFormData">
          <div class="form-group">
            <label for="name">
              {{ 'register.name' | translate }}
            </label>
            <input type="text" id="name" class="form-control" name="name" formControlName="name" required
                   [ngClass]="{ 'is-invalid': form.name.invalid && form.name.touched}"/>
            <div class="invalid-feedback" *ngIf="form.name.invalid && form.name.touched">
              {{ 'register.wrongName' | translate }}
            </div>
          </div>
        </div>
        <div class="col-6 userFormData">
          <div class="form-group">
            <label for="surname">
              {{ 'register.lastname' | translate }}
            </label>
            <input type="text" id="surname" class="form-control" name="surname" formControlName="surname" required
                   [ngClass]="{ 'is-invalid': form.surname.invalid && form.surname.touched}"/>
            <div class="invalid-feedback" *ngIf="form.surname.invalid && form.surname.touched">
              {{ 'register.wrongLastname' | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="userFormData">
        <div class="form-group">
          <label for="company">
            {{ 'register.company' | translate }}
          </label>
          <input type="text" id="company" class="form-control" name="company" formControlName="company" required
                 [ngClass]="{ 'is-invalid': form.company.invalid && form.company.touched}"/>
          <div class="invalid-feedback" *ngIf="form.company.invalid && form.company.touched">
            {{ 'register.wrongCompany' | translate }}
          </div>
        </div>
      </div>
      <div class="userFormData">
        <div class="form-group">
          <label for="phone">
            {{ 'register.phone' | translate }}
          </label>
          <input type="text" id="phone" class="form-control" name="phone" formControlName="phone" required
                 [ngClass]="{ 'is-invalid': form.phone.invalid && form.phone.touched}"/>
          <div class="invalid-feedback" *ngIf="form.phone.invalid && form.phone.touched">
            {{ 'register.wrongPhone' | translate }}
          </div>
        </div>
      </div>

      <div class=" userFormData">
        <div class="form-group">
          <label for="email">
            {{ 'register.email' | translate }}
          </label>
          <input type="text" id="email" class="form-control" name="email" formControlName="email" required
                 [ngClass]="{ 'is-invalid': form.email.invalid && form.email.touched}"/>
          <div class="invalid-feedback" *ngIf="form.email.invalid && form.email.touched">
            {{ 'register.wrongEmail' | translate }}
          </div>
        </div>
      </div>
      <div class=" userFormData">
        <div class="form-group">
          <label for="password">
            {{ 'register.password' | translate }}
          </label>
          <input type="password" id="password" class="form-control" name="password" formControlName="password" required
                 minlength="8" [ngClass]="{ 'is-invalid': form.password.invalid && form.password.touched}"/>
          <div class="invalid-feedback" *ngIf="form.password.invalid && form.password.touched">
            {{ 'register.wrongPassword' | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="form-r d-flex flex-column">
      <div class="">
        <div class="form-group flex-start flex-group-without-spacing">
          <p> {{ 'register.whoAreYou' | translate }}</p>
          <div class="form-check mb-2">
            <input class="form-check-input" type="radio" formControlName="role" name="role" id="role1"
                   value="ROLE_INVESTOR" checked>
            <label class="form-check-label pl-2" for="role1">
              {{ 'register.investor' | translate }}
            </label>
          </div>

          <div class="form-check mb-2">
            <input class="form-check-input" type="radio" formControlName="role" name="role" id="role2"
                   value="ROLE_OWNER">
            <label class="form-check-label pl-2" for="role2">
              {{ 'register.owner' | translate }}
            </label>
          </div>

          <div class="form-check">
            <input class="form-check-input" type="radio" formControlName="role" name="role" id="role3"
                   value="ROLE_BROKER">
            <label class="form-check-label pl-2" for="role3">
              {{ 'register.broker' | translate }}
            </label>
          </div>
        </div>
      </div>
      <div class="form-check">
          <div class="form-check pl-0">
            <input class="form-check-input" type="checkbox" required id="privacy" name="privacy"
                   formControlName="privacy" [ngModel]="isPrivacyAccepted()">
            <label class="form-check-label text-justify pl-2" for="privacy">
              {{ 'register.accept.first' | translate }} <a href=",,/../../assets/privacy-policy.pdf" target="_blank"
                                                           download="regulamin.pdf">
              {{ 'register.accept.third' | translate }} </a>
              <i style="font-size: smaller" *ngIf="isPrivacyAccepted()"><br/>{{ 'register.accept.acceptedCookies' | translate }}</i>
            </label>
          </div>
        <div class="form-check pl-0 mb-1">
          <input class="form-check-input" type="checkbox" required value="true" id="rules" name="rules"
                 formControlName="rules">
          <label class="form-check-label text-justify pl-2" for="rules">
            {{ 'register.accept.first' | translate }} <a href=",,/../../assets/regulamin.pdf" target="_blank"
                                                         download="regulamin.pdf">
            {{ 'register.accept.second' | translate }}</a>
          </label>
        </div>
        <div class="form-check pl-0 mb-1">
          <input class="form-check-input" type="checkbox" value="true" id="newsletter" name="newsletter"
                 formControlName="newsletter">
          <label class="form-check-label text-justify pl-2" for="newsletter">
            {{ 'register.newsletter.first' | translate }}  <b>
            {{ 'register.newsletter.second' | translate }} </b>
          </label>
        </div>
      </div>

      <div class="captcha pt-3 mt-2">
        <re-captcha (resolved)="resolved($event)" (error)="onError($event)" errorMode="handled"
                    siteKey="6LeMTe4ZAAAAABjOYaORGhJAlsW2m7LZD8hzmAUT">
        </re-captcha>
      </div>
      <div class=" ">
        <button class="btn btn-join btn-outline-light mr-sm-2" type="submit" [disabled]="!isActive()">
          {{ 'register.joinUs' | translate }}
        </button>
      </div>

      <div>
        <p class="showcase-small">
          {{ 'register.areYouUsing' | translate }}
          <a class="showcase-link" [routerLink]="['/login']">
            {{ 'register.login' | translate }}
          </a>
        </p>
      </div>
    </div>

  </form>
</div>
<app-footer></app-footer>
