import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NavComponent } from './nav/nav.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ApiUrlInterceptor } from './interceptors/apiUrl-interceptor';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { HomeComponent } from './home/home.component';
import { AuthComponent } from './auth/auth.component';
import { FooterComponent } from './footer/footer.component';
import { RegisterComponent } from './register/register.component';
import { RegisterSuccessComponent } from './register/register-success/register-success.component';
import { RegisterFailureComponent } from './register/register-failure/register-failure.component';
import { AdminComponent } from './admin/admin.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { MapComponent } from './map/map.component';
import { LocationsComponent } from './locations/locations.component';
import { AddLocationComponent } from './locations/add-location/add-location.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { ClientsComponent } from './clients/clients.component';
import { SolutionComponent } from './solution/solution.component';
import { AboutComponent } from './about/about.component';
import { CareerComponent } from './career/career.component';
import { CarouselComponent } from './carousel/carousel.component';
import { LocationDetailComponent } from './locations/location-detail/location-detail.component';
import { CookiesComponent } from './cookies/cookies.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { EditUserComponent } from './admin/edit-user/edit-user.component';
import { AccountComponent } from './account/account.component';
import { CloseMenuDirective } from './nav/close-menu.directive';
import { LocationFilterComponent } from './locations/location-filter/location-filter.component';
import { SetNewPasswordComponent } from './auth/set-new-password/set-new-password.component';
import { LoginModalComponent } from './auth/login-modal/login-modal.component';
import { MoreModalComponent } from './locations/location-detail/more-modal/more-modal.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    'assets/i18n/', // or whatever path you're using
    '.json',
  );
}

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    HomeComponent,
    AuthComponent,
    FooterComponent,
    RegisterComponent,
    RegisterSuccessComponent,
    RegisterFailureComponent,
    AdminComponent,
    ResetPasswordComponent,
    MapComponent,
    LocationsComponent,
    AddLocationComponent,
    ClientsComponent,
    SolutionComponent,
    AboutComponent,
    CareerComponent,
    CarouselComponent,
    LocationDetailComponent,
    CookiesComponent,
    EditUserComponent,
    AccountComponent,
    CloseMenuDirective,
    LocationFilterComponent,
    SetNewPasswordComponent,
    LoginModalComponent,
    MoreModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FontAwesomeModule,
    NgSelectModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'pl',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiUrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {}
}
