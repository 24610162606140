<div class="container-fluid pt-20">
  <div class="locations-container">
    <div class="d-flex justify-content-between">
      <h4 class="font-weight-bold">{{ 'locations.header' | translate }}</h4>
      <button
        class="btn btn-join btn-outline-light mr-sm-2 float-right"
        (click)="onAdd()"
        *ngIf="isAdmin()"
      >
        {{ 'locations.add' | translate }}
      </button>
    </div>
    <app-location-filter (locationsFilter)="onLocationsFilter($event)"></app-location-filter>
    <div class="row" *ngIf="!isLoading">
      <div class="col p-0">
        <table class="table">
          <thead class="thead-light">
          <tr>
            <th scope="col">No.</th>
            <th scope="col">{{ 'locations.name' | translate }}</th>
            <th scope="col">{{ 'locations.city' | translate }}</th>
            <th scope="col">{{ 'locations.type' | translate }}</th>
            <th scope="col">{{ 'locations.detail' | translate }}</th>
            <th scope="col" *ngIf="isAdmin()">{{ 'locations.edit' | translate }}</th>
            <th scope="col" *ngIf="isAdmin()">{{ 'locations.delete' | translate }}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let location of locations; index as i">
            <th scope="row" class="font-weight-bold">{{ i + 1 }}</th>
            <td>
              <b>{{ location.name }}</b>
            </td>
            <td>{{ location.city }}</td>
            <td>{{ 'locations.details.values.' + location.ltype | translate }}</td>
            <td>
              <button class="btn btn-secondary" (click)="onDetails(location)">
                {{ 'locations.detail' | translate }}
              </button>
            </td>
            <td *ngIf="isAdmin()">
              <button class="btn btn-info" (click)="onEdit(location)">
                {{ 'locations.edit' | translate }}
              </button>
            </td>
            <td *ngIf="isAdmin()">
              <button
                name="delete"
                type="button"
                class="btn btn-outline-danger"
                (click)="onDelete(location)"
              >
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  class="bi bi-trash-fill"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"
                  />
                </svg>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="map-container">
    <div class="map-frame">
      <div id="map"></div>
    </div>
  </div>
</div>
