import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { faClinicMedical, faCapsules } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  faClinicMedical = faClinicMedical;
  faCapsules = faCapsules;

  constructor(private router: Router) {}
}
