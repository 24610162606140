import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocationHttpService } from '../http/location-http.service';
import { Location } from '../model/location.model';
import * as L from 'leaflet';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss'],
})
export class LocationsComponent implements OnInit {
  locations: Location[] = [];
  isLoading = true;
  private map;
  private zoomIn;
  private innerWidth: number;
  mapMarkers = [];

  constructor(private locationHttpService: LocationHttpService, private router: Router) {}

  ngOnInit(): void {
    this.fetchLocations();
  }

  onLocationsFilter(locations: Location[]) {
    this.locations = locations;
    this.updatePins();
  }

  private fetchLocations() {
    this.locationHttpService.getLocations().subscribe((data) => {
      // @ts-ignore
      this.locations = data;
      this.locations.sort((a: Location, b: Location) => {
        if (a.ltype > b.ltype) {
          return 1;
        } else {
          return -1;
        }
      });
      this.initMap();
      this.isLoading = false;
    });
  }

  private initMap(): void {
    this.setZoom();
    this.map = L.map('map', {
      center: [52.112795, 19.211946],
      zoom: this.zoomIn,
      zoomSnap: 0.25,
    });
    const tiles = L.tileLayer(
      'https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png',
      {
        maxZoom: 20,
        attribution:
          '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
      },
    );
    tiles.addTo(this.map);
    // this.disableInteraction();
    this.updatePins();
  }

  private setZoom(): void {
    this.innerWidth = window.innerWidth;
    this.zoomIn = 6.5;
    if (innerWidth < 900) this.zoomIn = 6.25;
    else if (innerWidth < 600) this.zoomIn = 5.75;
    else if (innerWidth < 470) this.zoomIn = 5.25;
  }

  private updatePins() {
    this.mapMarkers.forEach((marker) => {
      this.map.removeLayer(marker);
    });
    this.mapMarkers = [];

    let coordsMap: Map<string, string> = new Map();

    this.locations.forEach((location) => {
      const latlngString = location.latitude.toString() + ',' + location.longitude.toString();
      if (coordsMap.get(latlngString) == null) {
        coordsMap.set(latlngString, location.ltype);
      } else {
        if (coordsMap.get(latlngString) !== location.ltype) {
          coordsMap.set(latlngString, 'other');
        }
      }
    });

    this.locations.forEach((location: Location) => {
      const latlngString = location.latitude.toString() + ',' + location.longitude.toString();
      const icon = this.getIcon(coordsMap.get(latlngString));
      const marker = L.marker([location.latitude, location.longitude], { icon: icon }).addTo(
        this.map,
      );
      this.mapMarkers.push(marker);
    });
  }

  private getIcon(ltype: string) {
    const url =
      'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-' +
      this.getColor(ltype) +
      '.png';

    const markerHtmlStyles = `
      background-color: ${this.getColor(ltype)};
      width: 1.5rem;
      height: 1.5rem;
      display: block;
      position: relative;
      border-radius: 3rem 3rem 0;
      transform: rotate(45deg);
      border: 1px solid #FFFFFF`;

    let icon = L.divIcon({
      className: 'my-custom-pin',
      iconAnchor: [12, 20],

      iconSize: [20, 50],
      popupAnchor: [0, -36],
      html: `<span style="${markerHtmlStyles}"/>`,
    });

    return icon;
  }

  private getColor(ltype) {
    switch (ltype) {
      case 'multi':
        return '#22475a';
      case 'dormitories':
        return '#216486';
      case 'single':
        return '#3a7da5';
      case 'hotel':
        return '#4796b1';
      case 'office':
        return '#429297';
      case 'logistic':
        return '#418072';
      case 'mall':
        return '#34855b';
      case 'tenement':
        return '#286e26';
      case 'other':
        return '#666666';
    }
  }

  onDelete(location: Location) {
    this.locationHttpService.deleteLocation(location.id).subscribe(() => {
      const index: number = this.locations.indexOf(location);
      if (index !== -1) {
        this.locations.splice(index, 1);
      }
    });
  }

  isAdmin() {
    return localStorage.getItem('role') == 'ROLE_ADMIN';
  }

  isInvestor() {
    return localStorage.getItem('role') == 'ROLE_INVESTOR';
  }

  onAdd() {
    this.router.navigate(['locations/add']);
  }

  onEdit(location: Location) {
    this.router.navigate(['locations/edit/', location.id]);
  }

  onDetails(location: Location) {
    this.router.navigate(['locations/details/', location.id]);
  }
}

export const fieldNames = new Map([
  ['name', 'Nazwa/Imię'],
  ['latitude', 'Szerokość geog.'],
  ['longitude', 'Długość geog.'],
  ['ltype', 'Typ nieruchomości'],
  ['city', 'Miasto'],
  ['status', 'Status'],
  ['statusMpzp', 'MPZP'],
  ['mpzp', 'Przeznaczenie nieruchomości MPZP'],
  ['linkMpzp', 'Link do MPZP'],
  ['wz', 'WZ'],
  ['pnb', 'PnB'],
  ['linkGoogle', 'Link google'],
  ['linkGeoportal', 'Link geoportal'],
  ['surface', 'Powierchnia'],
  ['isBuyAll', 'Czy trzeba kupić wszystko?'],
  ['buildingYear', 'Rok budowy'],
  ['buildingQuantity', 'Ilość budynków'],
  ['buildingContignationUnderground', 'Ilość kondygnacji podziemnych'],
  ['buildingContignationGround', 'Ilość kondygnacji naziemnych'],
  ['buildingStage', 'Etap budowy'],
  ['statusExpandable', 'Czy jest możliwa nadbudowa?'],
  ['pum', 'PUM'],
  ['puu', 'PUU'],
  ['puuOffice', 'PUU biurowe'],
  ['puuTrade', 'PUU handlowe'],
  ['surfaceCommon', 'Części wspólne'],
  ['price', 'Cena'],
  ['currency', 'Waluta'],
  ['pricePum', 'Cena PUM'],
  ['pricePuu', 'Cena PUU'],
  ['isGarage', 'Czy jest garaż?'],
  ['isGarageGround', 'Czy jest garaż naziemny?'],
  ['garageGroundFloors', 'Ilość pięter (naziemny)'],
  ['garageGroundQuantity', 'Ilość miejsc parkingowych (naziemny)'],
  ['garageGroundSurface', 'Powierchnia garażu (naziemny)'],
  ['isGarageUnderground', 'Czy jest garaż podziemny?'],
  ['isGarageUndergroundPlatforms', 'Czy ma platformy?'],
  ['garageUndergroundFloors', 'Ilość pięter (podziemny)'],
  ['garageUndergroundQuantity', 'Ilość miejsc parkingowych (podziemny)'],
  ['garageUndergroundSurface', 'Powierzchnia garażu (podziemny)'],
  ['garageQuantity', 'Ilość wszystkich miejsc parkingowych'],
  ['garageSurface', 'Całkowita powierzchnia garażu'],
  ['garageBikes', 'Ilość miejs na rowery'],
  ['statusElectricity', 'Czy jest prąd?'],
  ['statusWater', 'Czy jest woda?'],
  ['statusCanalization', 'Czy jest kanalizacja?'],
  ['statusGas', 'Czy jest gaz?'],
  ['currencyNoi', 'Waluta NOI'],
  ['currencyGoi', 'Waluta GOI'],
  ['monthsWault', 'WAULT, miesiące'],
  ['lyield', 'Szacunkowy yield'],
  ['certificates', 'Certyfikaty'],
  ['height', 'Wysokość'],
  ['streetMain', 'Główna ulica'],
  ['minBiol', 'Min. biol-czyn'],
  ['isExitroad', 'Czy jest zjazd z drogi na działkę?'],
  ['isRelicEvidence', 'Czy jest w ewidencji zabytków?'],
  ['isRelicRegister', 'Czy jest w rejestrze zabytków?'],
  ['isTenants', 'Czy są lokatorzy?'],
  ['transactionTypes', 'Typy transakcji'],
  ['transactionTerms', 'Warunki transakcji'],
  ['nature', 'Zieleń/drzewa do wysyłki'],
  ['isAccessible', 'Czy jest dostęp do drogi publicznej?'],
  ['other', 'Inne'],
  ['tax', 'Podatek'],
  ['servitude', 'Służebność'],
  ['state', 'Województwo'],
  ['street', 'Ulica'],
  ['number', 'Numer'],
  ['surname', 'Nazwisko'],
  ['company', 'Firma'],
  ['address', 'Adres'],
  ['phone', 'Telefon'],
  ['email', 'Email'],
  ['isOwner', 'Czy jest właścicielem?'],
  ['isPreliminary', 'Czy jest na przedwstępnej?'],
  ['datePreliminary', 'Data przedwstępnej'],
  ['isBroker', 'Czy jest pośrednikiem?'],
  ['isDirect', 'Czy mamy temat bezpośrednio?'],
  ['isAgreement', 'Czy jest umowa?'],
  ['agreementTermsAk', 'Warunki umowy właściciel-AK'],
  ['agreementTermsBroker', 'Warunki umowy właściciel-pośrednik'],
  ['pnumber', 'Numer działki'],
  ['territory', 'Terytorium'],
  ['precinct', 'Obręb'],
  ['kw', 'KW'],
]);
