<div class="cookie-container py-4" [ngClass]="{ 'cookie-container-hidden': isCookieHidden }">
    <div class="d-flex flex-column align-items-start px-5 py-4 ">
      <h3 class="cookies-header">{{ 'cookies.header' | translate }}</h3>
      <div class="cookies-inner">
        <p class="cookie-text mb-0">
            {{ 'cookies.description' | translate }}
          </p>
          <button class="btn btn-cookies-find-out" (click)="onFindOut()">
            {{ 'cookies.findOut' | translate }}
          </button>
          <button class="btn btn-primary btn-cookies-accept" (click)="onHide()">
            {{ 'cookies.accept' | translate }}
          </button>
        </div>
    </div>
</div>