<div class="geometric-shape"></div>
<div class="container-fluid container-first" id="map-pos">
  <div *ngIf="!isLocation()">
    <div class="mb-3">
      <h2 class="font-weight-bold">
        {{ 'map.title.first' | translate }}
        <span class="best"> {{ 'map.title.second' | translate }}</span>
        {{ 'map.title.third' | translate }}
      </h2>
    </div>
    <div>
      <p class="mb-3">
        {{ 'map.description' | translate }}
      </p>
      <p class="font-weight-bold cat-desc pt-2">
        {{ 'map.choose.first' | translate }}
        <span class="best"> {{ 'map.choose.second' | translate }} </span>
      </p>
    </div>
  </div>

  <div class="form-map-wrapper">
    <form [formGroup]="filterForm" class="form-tiles" (ngSubmit)="onSubmit()">
      <div
        class="form-check category"
        (click)="open($event, 'wielorodzinne')"
        [ngClass]="filterForm.controls['wielorodzinne'].value ? 'colourful' : 'grayed'"
      >
        <img class="category-image" src="../../assets/img/icons/buldings/many_family.svg" alt="" />
        <label>
          {{ 'map.category.manyFamilies' | translate }}
        </label>
      </div>
      <div
        class="form-check category"
        (click)="open($event, 'jednorodzinne')"
        [ngClass]="filterForm.controls['jednorodzinne'].value ? 'colourful' : 'grayed'"
      >
        <img class="category-image" src="../../assets/img/icons/buldings/family-house.svg" alt="" />
        <label>
          {{ 'map.category.family' | translate }}
        </label>
      </div>

      <div
        class="form-check category"
        (click)="open($event, 'akademiki')"
        [ngClass]="filterForm.controls['akademiki'].value ? 'colourful' : 'grayed'"
      >
        <img class="category-image" src="../../assets/img/icons/buldings/dormitory.svg" alt="" />
        <label class="text-center">
          {{ 'map.category.dormitory' | translate }}
        </label>
      </div>

      <div
        class="form-check category"
        (click)="open($event, 'hotele')"
        [ngClass]="filterForm.controls['hotele'].value ? 'colourful' : 'grayed'"
      >
        <img
          class="category-image"
          src="../../assets/img/icons/buldings/hotel.svg"
          alt=""
          style="height: 70px"
        />
        <label>
          {{ 'map.category.hotels' | translate }}
        </label>
      </div>

      <div
        class="form-check category"
        (click)="open($event, 'biurowce')"
        [ngClass]="filterForm.controls['biurowce'].value ? 'colourful' : 'grayed'"
      >
        <img class="category-image" src="../../assets/img/icons/buldings/office.svg" alt="" />
        <label>
          {{ 'map.category.office' | translate }}
        </label>
      </div>

      <div
        class="form-check category"
        (click)="open($event, 'logistyka')"
        [ngClass]="filterForm.controls['logistyka'].value ? 'colourful' : 'grayed'"
      >
        <img class="category-image" src="../../assets/img/icons/buldings/logistic.svg" alt="" />
        <label>
          {{ 'map.category.logistic' | translate }}
        </label>
      </div>

      <div
        class="form-check category"
        (click)="open($event, 'centra')"
        [ngClass]="filterForm.controls['centra'].value ? 'colourful' : 'grayed'"
      >
        <img class="category-image" src="../../assets/img/icons/buldings/centrum.svg" alt="" />
        <label>
          {{ 'map.category.center' | translate }}
        </label>
      </div>

      <div
        class="form-check category"
        (click)="open($event, 'kamienice')"
        [ngClass]="filterForm.controls['kamienice'].value ? 'colourful' : 'grayed'"
      >
        <img class="category-image" src="../../assets/img/icons/buldings/tenement.svg" alt="" />
        <label>
          {{ 'map.category.tenement' | translate }}
        </label>
      </div>

      <div class="form-action-sm form-action-mobile">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <button class="btn btn-primary btn-filter" (click)="onSubmit()">
            {{ 'map.filter' | translate }}
          </button>
          <div class="see-more">
            <p>
              {{ 'map.wantToSee' | translate }}
              <a class="showcase-link" [routerLink]="['/login']">
                {{ 'map.login' | translate }}
              </a>
            </p>
          </div>
        </div>
      </div>

    </form>
    
    <div class="map-container">
      <div class="map-frame">
        <div id="map"></div>
      </div>
    </div>
  </div>

  <div class="form-action-lg">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <button class="btn btn-primary btn-filter" (click)="onSubmit()">
        {{ 'map.filter' | translate }}
      </button>
      <div class="see-more">
        <p>
          {{ 'map.wantToSee' | translate }}
          <a class="showcase-link" [routerLink]="['/login']"> {{ 'map.login' | translate }}</a>
        </p>
      </div>
    </div>
  </div>
</div>
