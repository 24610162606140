import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register-failure',
  templateUrl: './register-failure.component.html',
  styleUrls: ['./register-failure.component.scss'],
})
export class RegisterFailureComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  onReturn() {
    this.router.navigate(['register']);
  }
}
