<div class="container-fluid pt-20">
  <div class="row">
    <div class="col p-0" *ngIf="!loading">
      <form [formGroup]="userForm" (ngSubmit)="onSubmit()" class="row-flex">
        <div class="form-group">
          <label for="role" class="font-weight-light"><b>{{ 'register.role' | translate }}</b></label>
          <select
            id="role"
            class="form-control"
            formControlName="role"
          >
            <option [value]="key"
                    *ngFor="let key of rolesSelection.keys()">
              {{ rolesSelection.get(key) }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="name">
            {{ 'register.name' | translate }}
          </label>
          <input [value]="user.name" type="text" id="name" class="form-control" name="name" formControlName="name" required
                 [ngClass]="{ 'is-invalid': form.name.invalid && form.name.touched}"/>
          <div class="invalid-feedback" *ngIf="form.name.invalid && form.name.touched">
            {{ 'register.wrongName' | translate }}
          </div>
        </div>

        <div class="form-group">
          <label for="surname">
            {{ 'register.lastname' | translate }}
          </label>
          <input type="text" id="surname" class="form-control" name="surname" formControlName="surname" required
                 [ngClass]="{ 'is-invalid': form.surname.invalid && form.surname.touched}" />
          <div class="invalid-feedback" *ngIf="form.surname.invalid && form.surname.touched">
            {{ 'register.wrongLastname' | translate }}
          </div>
        </div>

        <div class="form-group">
          <label for="company">
            {{ 'register.company' | translate }}
          </label>
          <input type="text" id="company" class="form-control" name="company" formControlName="company" required
                 [ngClass]="{ 'is-invalid': form.company.invalid && form.company.touched}" />
          <div class="invalid-feedback" *ngIf="form.company.invalid && form.company.touched">
            {{ 'register.wrongCompany' | translate }}
          </div>
        </div>

        <div class="form-group">
          <label for="phone">
            {{ 'register.phone' | translate }}
          </label>
          <input type="text" id="phone" class="form-control" name="phone" formControlName="phone" required
                 [ngClass]="{ 'is-invalid': form.phone.invalid && form.phone.touched}" />
          <div class="invalid-feedback" *ngIf="form.phone.invalid && form.phone.touched">
            {{ 'register.wrongPhone' | translate }}
          </div>
        </div>

        <div class="form-group">
          <label for="email">
            {{ 'register.email' | translate }}
          </label>
          <input type="text" id="email" class="form-control" name="email" formControlName="email" required
                 [ngClass]="{ 'is-invalid': form.email.invalid && form.email.touched}" />
          <div class="invalid-feedback" *ngIf="form.email.invalid && form.email.touched">
            {{ 'register.wrongEmail' | translate }}
          </div>
        </div>

        <button class="btn btn-primary ml-2" type="submit">
          {{ 'editUser.edit' | translate }}
        </button>
      </form>
    </div>
  </div>
</div>
