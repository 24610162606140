import { Component, OnInit } from '@angular/core';
import * as L from 'leaflet';
import { LocationHttpService } from '../http/location-http.service';
import { Location } from '../model/location.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { mark } from '@angular/compiler-cli/src/ngtsc/perf/src/clock';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  private map: L.Map;
  private allLocations: Location[];
  private currentLocations: Location[];
  private innerWidth;
  private zoomIn;
  filterForm: FormGroup;
  mapMarkers = [];

  constructor(
    private locationHttpService: LocationHttpService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.fetchLocations();
    this.initForm();
    this.setZoom();
  }

  private initForm() {
    this.filterForm = this.formBuilder.group({
      wielorodzinne: [true, [Validators.required]],
      jednorodzinne: [true, [Validators.required]],
      akademiki: [true, [Validators.required]],
      hotele: [true, [Validators.required]],
      biurowce: [true, [Validators.required]],
      logistyka: [true, [Validators.required]],
      centra: [true, [Validators.required]],
      kamienice: [true, [Validators.nullValidator]],
    });
  }

  private setZoom(): void {
    this.innerWidth = window.innerWidth;
    console.log('Inner width:', this.innerWidth);
    this.zoomIn = 6.5;
    if (innerWidth < 900) this.zoomIn = 6.25;
    else if (innerWidth < 600) this.zoomIn = 5.75;
    else if (innerWidth < 470) this.zoomIn = 5.25;
  }

  private initMap(): void {
    this.map = L.map('map', {
      center: [52.112795, 19.211946],
      zoom: this.zoomIn,
      zoomSnap: 0.25,
    });

    var tiles = L.tileLayer(
      'https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png',
      {
        maxZoom: 20,
        attribution:
          '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
      },
    );
    tiles.addTo(this.map);

    this.updatePins();
  }

  onSubmit() {
    this.filter();
  }

  private filter() {
    let result: Location[] = [];
    let types: string[] = this.getTypes();

    this.allLocations.forEach((location: Location) => {
      if (types.includes(location.ltype)) {
        result.push(location);
      }
    });
    this.currentLocations = result;

    this.updatePins();
  }

  private updatePins() {
    this.mapMarkers.forEach((marker) => {
      this.map.removeLayer(marker);
    });
    this.mapMarkers = [];

    let coordsMap: Map<string, string> = new Map();

    this.currentLocations.forEach((location) => {
      const latlngString = location.latitude.toString() + ',' + location.longitude.toString();
      if (coordsMap.get(latlngString) == null) {
        coordsMap.set(latlngString, location.ltype);
      } else {
        if (coordsMap.get(latlngString) !== location.ltype) {
          coordsMap.set(latlngString, 'other');
        }
      }
    });

    this.currentLocations.forEach((location: Location) => {
      const latlngString = location.latitude.toString() + ',' + location.longitude.toString();
      const icon = this.getIcon(coordsMap.get(latlngString));
      const marker = L.marker([location.latitude, location.longitude], { icon: icon }).addTo(
        this.map,
      );
      this.mapMarkers.push(marker);
    });
  }

  private disableInteraction() {
    this.map.touchZoom.disable();
    this.map.doubleClickZoom.disable();
    this.map.scrollWheelZoom.disable();
    this.map.boxZoom.disable();
    this.map.keyboard.disable();
    this.map.dragging.disable();
    this.map.zoomControl.remove();
  }

  private fetchLocations() {
    this.locationHttpService.getLocations().subscribe((data) => {
      // @ts-ignore
      this.allLocations = data;
      this.currentLocations = Object.assign([], this.allLocations);
      this.initMap();
    });
  }

  private getIcon(ltype: string) {
    const url =
      'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-' +
      this.getColor(ltype) +
      '.png';

    const markerHtmlStyles = `
      background-color: ${this.getColor(ltype)};
      width: 1.5rem;
      height: 1.5rem;
      display: block;
      position: relative;
      border-radius: 3rem 3rem 0;
      transform: rotate(45deg);
      border: 1px solid #FFFFFF`;

    let icon = L.divIcon({
      className: 'my-custom-pin',
      iconAnchor: [12, 20],

      iconSize: [20, 50],
      popupAnchor: [0, -36],
      html: `<span style="${markerHtmlStyles}"/>`,
    });

    return icon;
  }

  private getColor(ltype) {
    switch (ltype) {
      case 'multi':
        return '#22475a';
      case 'dormitories':
        return '#216486';
      case 'single':
        return '#3a7da5';
      case 'hotel':
        return '#4796b1';
      case 'office':
        return '#429297';
      case 'logistic':
        return '#418072';
      case 'mall':
        return '#34855b';
      case 'tenement':
        return '#286e26';
      case 'other':
        return '#666666';
    }
  }

  private getTypes() {
    const types: string[] = [];

    if (this.filterForm.get('wielorodzinne').value) {
      types.push('multi');
    }
    if (this.filterForm.get('jednorodzinne').value) {
      types.push('single');
    }
    if (this.filterForm.get('akademiki').value) {
      types.push('dormitories');
    }
    if (this.filterForm.get('hotele').value) {
      types.push('hotel');
    }
    if (this.filterForm.get('biurowce').value) {
      types.push('office');
    }
    if (this.filterForm.get('logistyka').value) {
      types.push('logistic');
    }
    if (this.filterForm.get('centra').value) {
      types.push('mall');
    }
    if (this.filterForm.get('kamienice').value) {
      types.push('tenement');
    }

    return types;
  }

  public open(event, item) {
    let value = this.filterForm.controls[item].value;
    if (value === true) {
      this.filterForm.controls[item].setValue(false);
    } else {
      this.filterForm.controls[item].setValue(true);
    }
  }

  isLocation() {
    return this.route.snapshot['_routerState'].url.includes('locations');
  }
}
