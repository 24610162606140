<img class="top-img" src="../../../assets/img/nav_short/px11_short2.jpg" alt="">
<div class="overlay">
</div>

<div class="container mt-5">
  <div class="row">
    <div class="col-12 center">
      <h2 class="mb-4">{{ 'reset.title' | translate }} <span id="logo">Asset Kingdom</span></h2>
    </div>
  </div>

  <div class="row">
    <div class="col-12 center">
      <p>{{ 'reset.description' | translate }}</p>
    </div>
  </div>

  <form [formGroup]="resetForm"  class="d-flex flex-column">
    <div class="row">
      <div class="col-4 offset-4 center input-row">
        <div class="form-group">
          <label for="email" class="font-weight-bold">Email</label>
          <input
            type="email"
            id="email"
            class="form-control"
            formControlName="email"
            name="email"
            email
            required
          />
          <div class="invalid-feedback">
            {{ 'register.wrongEmail' | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 center">
        <button class="btn btn-primary mt-3" (click)="onSubmit()" type="submit">
          {{ 'reset.button' | translate }}
        </button>
      </div>
    </div>
  </form>

</div>
