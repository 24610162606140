import { Plot } from './plot.model';
import { Address } from './address.model';
import { Owner } from './owner.model';

export class Location {
  public id?: string;
  public name?: string;
  public latitude?: number;
  public longitude?: number;
  public ltype?: string;
  public city?: string;
  public status?: string;
  public statusMpzp?: string;
  public mpzp?: string;
  public linkMpzp?: string;
  public wz?: string;
  public pnb?: string;
  public linkGoogle?: string;
  public linkGeoportal?: string;
  public surface?: number;
  public isBuyAll?: boolean;
  public buildingYear?: number;
  public buildingQuantity?: number;
  public buildingContignationUnderground?: number;
  public buildingContignationGround?: number;
  public buildingStage?: string;
  public statusExpandable?: string;
  public pum?: number;
  public puu?: number;
  public puuOffice?: number;
  public puuTrade?: number;
  public surfaceCommon?: number;
  public price?: number;
  public currency?: string;
  public pricePum?: number;
  public pricePuu?: number;
  public isGarage?: boolean;
  public isGarageGround?: boolean;
  public garageGroundFloors?: number;
  public garageGroundQuantity?: number;
  public garageGroundSurface?: number;
  public isGarageUnderground?: boolean;
  public isGarageUndergroundPlatforms?: boolean;
  public garageUndergroundFloors?: number;
  public garageUndergroundQuantity?: number;
  public garageUndergroundSurface?: number;
  public garageQuantity?: number;
  public garageSurface?: number;
  public garageBikes?: number;
  public statusElectricity?: string;
  public statusWater?: string;
  public statusCanalization?: string;
  public statusGas?: string;
  public currencyNoi?: string;
  public currencyGoi?: string;
  public monthsWault?: string;
  public lyield?: number;
  public certificates?: string;
  public height?: number;
  public streetMain?: string;
  public minBiol?: string;
  public isExitroad?: boolean;
  public isRelicEvidence?: boolean;
  public isRelicRegister?: boolean;
  public isTenants?: boolean;
  public transactionTypes?: string;
  public transactionTerms?: string;
  public nature?: string;
  public isAccessible?: boolean;
  public other?: string;
  public tax?: number;
  public servitude?: string;
  public addresses?: Address[] = [];
  public owners?: Owner[] = [];
  public plots?: Plot[] = [];
  constructor() {}
}
