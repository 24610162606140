import { Component, OnInit } from '@angular/core';
import { User } from '../model/user.model';
import { UserHttpService } from '../http/user-http.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  users: User[];
  isLoading: any = true;

  constructor(private userHttpService: UserHttpService, private router: Router) {}

  ngOnInit(): void {
    this.fetchUsers();
  }

  private fetchUsers(page: number = 0) {
    this.userHttpService.getUsers().subscribe((data) => {
      // @ts-ignore
      this.users = data.content.sort((a: User, b: User) => {
        return a.roles[0].name > b.roles[0].name;
      });
      this.users = this.users.filter((user: User) => {
        return user.roles[0].name != 'ROLE_ADMIN';
      });
      this.isLoading = false;
    });
  }

  onActivate(user: User) {
    const activatedUser: User = Object.assign({}, user);
    activatedUser.activated = true;

    this.userHttpService.updateUser(activatedUser).subscribe(() => {
      this.fetchUsers();
    });
  }

  onDeactivate(user: User) {
    const activatedUser: User = Object.assign({}, user);
    activatedUser.activated = false;

    this.userHttpService.updateUser(activatedUser).subscribe(() => {
      this.fetchUsers();
    });
  }

  getRoleString(name: string) {
    switch (name) {
      case 'ROLE_INVESTOR':
        return 'Inwestor';
      case 'ROLE_BROKER':
        return 'Pośrednik';
      case 'ROLE_OWNER':
        return 'Właściciel';
      default:
        return 'Inna/Brak';
    }
  }

  isActivated(user: User) {
    return user.activated;
  }

  onDelete(user: User) {
    this.userHttpService.deleteUser(user).subscribe(() => {
      this.fetchUsers();
    });
  }

  onEdit(user: User) {
    this.router.navigate(['admin/user/', user.id]);
  }
}
