import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { UserHttpService } from '../http/user-http.service';
import { Role, User } from '../model/user.model';
import { RecaptchaErrorParameters } from 'ng-recaptcha';
import { CustomValidators } from './custom-validators';

class UserService {}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  userForm: FormGroup;
  isResolved = false;
  isCookie = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private userHttpService: UserHttpService,
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  onSubmit() {
    this.isCookie = sessionStorage.getItem('cookies') === 'true';

    if (this.userForm.get('privacy').value == false) {
      return;
    }
    const user: User = this.createUser();
    this.userHttpService.createUser(user).subscribe(
      (data) => {
        this.userForm.reset();
        this.router.navigate(['register/success']);
      },
      (err) => {
        this.userForm.reset();
        this.router.navigate(['register/failure']);
      },
    );
  }

  private initForm() {
    this.userForm = this.formBuilder.group({
      name: [null, [Validators.required]],
      surname: [null, [Validators.required]],
      company: [null, [Validators.required]],
      phone: [null, [Validators.required]],
      email: [null, Validators.compose([Validators.required, Validators.email])],
      password: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.patternValidator(/\d/, { hasNumber: true }),
          CustomValidators.patternValidator(/[a-zA-z]/, { hasLetter: true }),
          CustomValidators.patternValidator(/[!@#$%^&*()_+{}:"|<>?\-=\[\]'\\,.\/]/, {
            hasSpecialCharacters: true,
          }),
        ]),
      ],
      role: [null, [Validators.required]],
      newsletter: [null, [Validators.nullValidator]],
      rules: [null, [Validators.nullValidator]],
      privacy: [null, [Validators.nullValidator]],
    });
    this.userForm.reset();
  }

  get form() {
    return this.userForm.controls;
  }

  private createUser() {
    let user: User = new User(
      null,
      this.userForm.get('name').value,
      this.userForm.get('surname').value,
      this.userForm.get('company').value,
      this.userForm.get('phone').value,
      this.userForm.get('email').value,
      this.userForm.get('password').value,
      [new Role(null, this.userForm.get('role').value)],
      this.userForm.get('newsletter').value,
      false,
    );
    if (this.userForm.get('newsletter').value == null) {
      user.newsletter = false;
    }
    return user;
  }

  public resolved(captchaResponse: string): void {
    this.isResolved = true;
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  }

  isActive() {
    return (
      this.isResolved &&
      this.userForm.get('rules').value === true &&
      this.userForm.get('privacy').value === true
    );
  }

  isPrivacyAccepted() {
    return sessionStorage.getItem('cookies') === 'true';
  }
}
