import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Asset Kingdom';
  isAuthenticated: boolean;
  private userSub: Subscription;
  isCookieHidden = false;
  cookie$: Subject<boolean> = new Subject();
  language: string = 'pl';

  constructor(private authService: AuthService, private translate: TranslateService) {
    translate.setDefaultLang('pl');
    translate.use('pl');
  }

  ngOnInit() {
    if (sessionStorage.getItem('cookies') === 'true') {
      this.isCookieHidden = true;
    }
    this.authService.autoLogin();
    this.userSub = this.authService.user.subscribe((user) => {
      this.isAuthenticated = !!user;
    });
    if (localStorage.getItem('language') === 'en') {
      this.useLanguage();
    }
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }

  hideCookieBanner() {
    this.isCookieHidden = true;
  }

  onLanguageChange() {
    this.useLanguage();
  }

  useLanguage() {
    if (this.language === 'pl') {
      this.language = 'en';
      localStorage.setItem('language', 'en');
      this.translate.use(this.language);
    } else {
      this.language = 'pl';
      localStorage.setItem('language', 'pl');
      this.translate.use(this.language);
    }
  }
}
