import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './register/register.component';
import { RegisterSuccessComponent } from './register/register-success/register-success.component';
import { RegisterFailureComponent } from './register/register-failure/register-failure.component';
import { AdminComponent } from './admin/admin.component';
import { AuthGuard } from './auth/auth.guard';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { LocationsComponent } from './locations/locations.component';
import { AddLocationComponent } from './locations/add-location/add-location.component';
import { LocationDetailComponent } from './locations/location-detail/location-detail.component';
import { CareerComponent } from './career/career.component';
import { EditUserComponent } from './admin/edit-user/edit-user.component';
import { AccountComponent } from './account/account.component';
import { SetNewPasswordComponent } from './auth/set-new-password/set-new-password.component';
import { AdminGuard } from './auth/admin-guard';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
  },
  { path: 'login', component: AuthComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'admin', component: AdminComponent, canActivate: [AdminGuard] },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'locations', component: LocationsComponent, canActivate: [AuthGuard] },
  { path: 'register/success', component: RegisterSuccessComponent },
  { path: 'register/failure', component: RegisterFailureComponent },
  { path: 'locations/add', component: AddLocationComponent, canActivate: [AuthGuard] },
  {
    path: 'locations/details/:locationId',
    component: LocationDetailComponent,
    canActivate: [AuthGuard],
  },
  { path: 'career', component: CareerComponent, data: { scroll: true } },
  { path: 'admin/user/:userId', component: EditUserComponent, canActivate: [AdminGuard] },
  { path: 'account', component: AccountComponent, canActivate: [AuthGuard] },
  {
    path: 'locations/edit/:locationId',
    component: AddLocationComponent,
    canActivate: [AdminGuard],
  },
  { path: 'auth/reset-password/set-password', component: SetNewPasswordComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
